<template>
  <div class="wisk-grid-header-container" ref="gridHeaderContainer">
    <div class="d-flex flex-wrap justify-content-between align-items-center pt-1 pb-2" style="gap: 15px 10px">
      <b-button @click="togglePanel" variant="link" class="px-1 wisk-grid-bt-pin-settings">
        <icon name="wisk-bars" :scale="0.8" :class="{ 'text-primary': panelOpen, 'text-secondary': !panelOpen }" />
      </b-button>
      <div class="wisk-grid-view w-sm-view-buttons">
        <b-button class="text-decoration-none grid-view-value flex-grow-1 py-0 d-flex align-items-center" :variant="gridViewsOpen ? 'primary' : 'outline-primary'" @click="toggleGridViews">
          {{ translations.groupGridViews[`${gridName}_${currentView.id}`] }}
        </b-button>
        <filterInfoTooltip :columns="columns" :filterConfig="currentView?.filterConfig" @openFiltersPanel="openSpecificMultiPanel(3)">
          <b-button class="text-decoration-none setting py-0 d-flex align-items-center" :variant="multiPanelOpen ? 'primary' : 'outline-primary'" @click="toggleMultiPanel">
            <icon name="wisk-options"></icon>
            <b-badge v-if="filtersCount" variant="warning" class="ms-2 position-absolute">
              {{ filtersCount }}
            </b-badge>
          </b-button>
        </filterInfoTooltip>
      </div>

      <div class="ms-auto ms-sm-0 w-sm-icon-buttons align-self-center">
        <slot name="icon-buttons"></slot>
      </div>

      <wiskInput infoTooltipKey="213fe260-04e3-46e7-a548-24a4f2e6a000" no-gutters showClearButton icon="wisk-search" :label="translations.txtGenericTypeToSearch" inputClass="px-3" v-model="query" ref="searchBoxContainer"
        v-if="!headerConfig.hideSearch" />

      <div class="mx-auto"></div>

      <slot></slot>
    </div>

    <span v-show="multiPanelOpen" id="grid-view-settings-panel" :class="{ pinned }">
      <portal-target :name="`grid-view-settings-${gridName}`" :slot-props="{ selectedMultiPaneltedTab }" />
    </span>
    <span v-show="gridViewsOpen" id="grid-views-panel" :class="{ pinned }">
      <portal-target :name="`grid-views-${gridName}`" />
    </span>

  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getPreferences, setPreferences } from '@/modules/storage'
import filterInfoTooltip from '@/components/grids/WiskGridFilterInfoTooltip'

export default {
  name: 'WiskGridHeader',
  emits: ['selectView', 'change', 'gridPanel', 'removeView', 'addView', 'heightChanged', 'searchQuery', 'pinned', 'cloneView'],
  components: { filterInfoTooltip },
  props: {
    innitialQuery: String,
    gridName: String,
    columns: Array,
    views: { type: Array, default: () => [] },
    currentView: Object,
    headerConfig: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      debug: 0,
      query: '',
      counter: 0,
      unMounted: false,
      key: 1,
      pinned: false,
      timeoutId: null,
      panelOpen: false,
      containerWidth: 992,
      multiPanelOpen: false,
      gridViewsOpen: false,
      selectedMultiPaneltedTab: null
    }
  },
  computed: {
    ...mapState(['translations', 'windowResizeKey', 'venue', 'user']),
    filtersCount() {
      return this.currentView?.filterConfig?.filters?.length || 0
    }
  },
  methods: {
    openSpecificMultiPanel(selectedTab) {
      this.multiPanelOpen = true
      this.gridViewsOpen = false
      this.selectedMultiPaneltedTab = selectedTab
      this.checkPin()

      setTimeout(() => {
        this.selectedMultiPaneltedTab = null
      }, 0)
    },
    togglePanel() {
      if (!this.multiPanelOpen && !this.gridViewsOpen) {
        this.gridViewsOpen = true
      } else if (this.multiPanelOpen) {
        this.multiPanelOpen = false
      } else if (this.gridViewsOpen) {
        this.gridViewsOpen = false
      }

      this.checkPin()
    },
    toggleMultiPanel() {
      this.multiPanelOpen = !this.multiPanelOpen
      this.gridViewsOpen = false

      this.checkPin()
    },
    toggleGridViews() {
      this.gridViewsOpen = !this.gridViewsOpen
      this.multiPanelOpen = false

      this.checkPin()
    },
    removeView(viewId) {
      this.$emit('removeView', viewId)
    },
    addView(title) {
      this.$emit('addView', title)
    },
    checkPin() {
      this.pinned = false
      this.panelOpen = this.multiPanelOpen || this.gridViewsOpen

      if (this.panelOpen) {
        let parent = this.$refs?.gridHeaderContainer?.closest?.('.wisk-grid-container')

        if (parent) {
          this.pinned = parent.clientHeight > 240
          setPreferences(`${this.venue.id}.grids.${this.gridName}.gridViewsPanelPinned`, this.pinned)
        }
      } else {
        setPreferences(`${this.venue.id}.grids.${this.gridName}.gridViewsPanelPinned`, false)
      }
    },
    onWindowResize() {
      if (!this.unMounted) {
        setTimeout(() => {
          if (this.$refs.gridHeaderContainer && this.$refs.gridHeaderContainer.clientHeight) {
            this.$emit('heightChanged', this.$refs.gridHeaderContainer.clientHeight)
          }

          this.checkPin()
        }, 0)
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.query = this.innitialQuery
      this.onWindowResize()

      this.pinned = getPreferences(`${this.venue.id}.grids.${this.gridName}.gridViewsPanelPinned`, false)

      if (this.pinned && !this.multiPanelOpen && !this.gridViewsOpen) {
        this.gridViewsOpen = true
      }
    }, 0)
  },
  beforeUnmount() {
    this.unMounted = true
    clearTimeout(this.timeoutId)
  },
  watch: {
    windowResizeKey: 'onWindowResize',
    pinned() {
      this.$emit('pinned', this.pinned)

      if (this.pinned && !this.multiPanelOpen && !this.gridViewsOpen) {
        this.gridViewsOpen = true
      }
    },
    query(query) {
      this.$emit('searchQuery', query)
    }
  }
}
</script>
<style lang="scss">
.bt-manage-grid-views {
  position: absolute;
  top: -20px;
  right: -5px;
}

.wisk-grid-bt-pin-settings {

  &:hover {
    transform: scale(1.1);
  }
}

.wisk-grid-view {
  display: flex;
  position: relative;

  label {
    position: absolute;
    left: 16px;
    top: 0;
    font-size: 14px;
    color: #0973e5;
  }

  .setting {
    border-radius: 0 4px 4px 0;
    border-left: none;
  }

  .grid-view-value {
    border-radius: 4px 0px 0px 4px;
    min-width: 150px;
    text-align: left;
    font-weight: 700;
    display: flex;
    align-items: center;
  }

  .btn {
    height: 33px;
  }
}

.wisk-grid-header-container>.d-flex>*:not(.mx-auto) {
  margin: 0 !important;
}

@media (max-width: 575px) {
  .wisk-grid-header-container>.d-flex>* {
    width: 100%;
  }

  .wisk-grid-header-container>.d-flex>.w-sm-view-buttons {
    width: 75%;
  }

  .wisk-grid-header-container>.d-flex>.ms-auto {
    width: calc(25% - 10px);
  }

  .wisk-grid-header-container>.d-flex>.mx-auto {
    display: none;
  }
}
</style>
