<template>
  <wiskInputGroup @errorCountChanged="setValidState" :disabled="disabled" style="" @operationsChange="onChangeAll" ref="inputGroup">
    <b-row>
      <b-col cols="7" md="8">
        <wiskDistributorFind :distributor="distributor" inline v-if="!isPrivateDistributor && !distributor.id" @select="onWiskDistributorSelected" @titleChange="onTitleChange" />

        <wiskInput v-if="distributor.id || isPrivateDistributor" infoTooltipKey="95619fef-3d70-4361-acd9-b5d38e85ff39" :label="translations.txtGenericName" :modelValue="distributorTitle"
          required @operation="onChange" operation="title" :helperText="titleHelperText" fitChildren :fitChildrenCols="1">
          <b-button @click="handleLinkUnlinkButton" variant="link" size="sm" class="mt-1" style="pointer-events: all; margin-left: -5px;"
            v-tooltip="translations[distributor.wisk_distributor ? 'txtDistributorPublincLinked' : 'txtDistributorPublincLinkedNot']">
            <icon name="wisk-link" :scale="0.8" :class="`text-${distributor.wisk_distributor ? 'primary' : 'secondary'}`" />
          </b-button>
        </wiskInput>
      </b-col>
      <b-col cols="5" md="4" class="text-center">
        <wiskInput v-if="!distributor.id && distributorTitle && !wiskDistributor" infoTooltipKey="7b1628dc-2e2c-4ce9-99ae-b85f7931d38e" label="Private Distributor" inputType="checkbox" v-model="isPrivateDistributor" />
        <b-button v-if="distributor?.id" variant="link" @click="setGlobalAction({ action: { distributorId: distributor.id }, type: 'itemsForDistributor' })">
          {{ translations.txtDistributorViewItems }}
        </b-button>
      </b-col>
      <!-- <b-col cols="12" md="4">
        <wiskSelect infoTooltipKey="b638b8e0-6eb4-4873-9a81-3499e568e760" :items="countries" @operation="onChange" :modelValue="distributor.country" operationEmpty="country_clear"
          operation="country" :label="translations.txtGenericCountry" trackBy="code" />
      </b-col> -->
      <b-col cols="12" md="4">
        <wiskSelect infoTooltipKey="a59a400d-6f3b-4820-89bb-dfe976c374a0" :items="activeGLAccounts" @operation="onChange" :modelValue="distributor.gl_account_id" operationEmpty="gl_account_id_clear"
          operation="gl_account_id" :label="translations.txtGLAccount" :addNewItem="addGLAccountConfig" v-if="currentPermissionsByType.gl_account_assign" />
      </b-col>
      <b-col cols="12" md="4">
        <wiskInput infoTooltipKey="005e0695-8eb9-4fe2-b17a-159783b14b05" :label="translations.txtDistributorCustomerAccountNumber" operation="customer_number" operationEmpty="customer_number_clear"
          @operation="onChange" triggerInputOnLoad :modelValue="customerNumber" />
      </b-col>
      <b-col cols="12" md="4" v-if="accountingDistributors && accountingDistributors.length">
        <wiskSelect infoTooltipKey="004fb00d-a10f-4b9d-a0f7-47df7eb65390" :items="accountingDistributors" @operation="onChange" :modelValue="distributor.id_in_accounting"
          operation="id_in_accounting" :label="translations.txtAccountingDistributor" v-if="currentPermissionsByType.invoice_send_to_accounting" trackBy="id_in_accounting" />
      </b-col>
      <b-col cols="12" md="4">
        <wiskSelect infoTooltipKey="1dc0973b-e5f8-48da-94bc-6c438c3b086c" :items="activeTaxes" @operation="onChange" :label="translations.txtTax" :addNewItem="addTaxConfig"
          v-if="currentPermissionsByType.tax_rate_assign && venue.taxes_enabled" :modelValue="distributor.tax_rate_id" operation="tax_rate_id" operationEmpty="tax_rate_id_clear" />
      </b-col>
      <b-col cols="12" md="4">
        <wiskInput infoTooltipKey="cdd5ae75-39`da-4d73-aad0-38189cd2532e" :label="translations.txtDistributorsExcludePriceOrders" operation="exclude_price_from_purchase_orders"
          @operation="onChange" triggerInputOnLoad inputType="checkbox" :modelValue="distributor.exclude_price_from_purchase_orders" />
      </b-col>
      <b-col cols="12" md="4">
        <wiskInput infoTooltipKey="9522b81d-f60e-439a-ae0d-4a084c56accb" :label="translations.txtDistributorsAllowAutoOrdering" operation="allow_auto_ordering"
          @operation="onChange" triggerInputOnLoad inputType="checkbox" :modelValue="distributor.allow_auto_ordering" />
      </b-col>
      <b-col cols="12" md="4">
        <wiskInput infoTooltipKey="8dc2dd6a-3f0a-4ac1-a2cb-51e7ca3a1eaf" :label="translations.txtVenueOperationsIdealStockDays" operation="ideal_stock_days" operationEmpty="ideal_stock_days_clear"
          :modelValue="distributor.ideal_stock_days" inputType="number" :decimals="1" decimalsAsNeeded @operation="onChange" />
      </b-col>
    </b-row>

    <wiskInput inputClass="mt-2" infoTooltipKey="973c543b-bb6b-47df-ae62-7f7746049f3a" :label="translations.txtGenericNotes" operation="notes"
      :modelValue="distributor.notes" multiline autoHeightTextArea @operation="onChange" />
    <wiskInput v-if="user?.is_wisk_user || user?.god_mode" infoTooltipKey="f79f238d-2d88-4d79-ac3f-64f9467e189c" :label="translations.txtVenueOperationsInvoiceProcessingNotes" operation="internal_notes"
    :modelValue="distributor.internal_notes" multiline autoHeightTextArea @operation="user?.god_mode ? onChange($event) : () => { }" :disabled="!user?.god_mode" />

    <portalCredentials v-if="distributor.show_portal_credentials" :distributorId="distributor.id" @update="onChange" />

    <customFields target="distributor" :item="distributor" @operation="onChange" />

  </wiskInputGroup>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import api from '@/api'
import { compareStrings } from '@/modules/utils'
import portalCredentials from '@/components/distributors/DistributorPortalCredentials'
import wiskDistributorFind from '@/components/distributors/WiskDistributorFind'
import customFields from '@/components/customFields/CustomFieldsRender'

export default {
  name: 'DistributorOperations',
  emits: ['update:modelValue', 'validStateChanged', 'wiskDistributorOperations', 'linkDistributor'],
  components: { portalCredentials, customFields, wiskDistributorFind },
  props: {
    modelValue: Array,
    disabled: Boolean,
    distributor: Object
  },
  data() {
    return {
      accountingDistributors: [],
      operations: [],
      valid: true,
      isPrivateDistributor: false,
      distributorTitle: '',
      wiskDistributor: null
    }
  },
  computed: {
    ...mapGetters(['activeGLAccounts', 'activeTaxes', 'venue', 'countries', 'countriesByCode']),
    ...mapState(['customFieldsByTarget', 'translations', 'glAccounts', 'currentPermissionsByType', 'distributorCredentialsByDistributorId', 'user']),
    titleHelperText() {
      if (this.distributor && this.distributor.id && this.distributor.wisk_distributor) {
        return this.distributor.wisk_distributor.title + ` (${this.countriesByCode[this.distributor.wisk_distributor.country].title})`
      }
      return ''
    },
    addTaxConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'taxEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: tax => {
                callbackItemInjected(tax)
              }
            }
          })
        },
        label: this.translations.txtTaxesNew
      }
    },
    addGLAccountConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'glAccountEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: glAccount => {
                callbackItemInjected(glAccount)
              }
            }
          })
        },
        label: this.translations.txtGLAccountNew
      }
    },
    customerNumber() {
      if (this.distributor?.id) {
        return this.distributorCredentialsByDistributorId?.[this.distributor.id]?.customer_number || ''
      }
      return ''
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    handleLinkUnlinkButton() {
      if (this.distributor.wisk_distributor) {
        this.setGlobalAction({ type: 'wiskDistributorEdit', action: { id: this.distributor.wisk_distributor.id, onChange: this.onWiskDistributorSelected } })
      } else {
        this.$emit('linkDistributor')
      }
    },
    taxSchemeSelectorLabelTranslator(item) {
      return this.translations.groupTaxSchemes[item.id]
    },
    onWiskDistributorSelected(wiskDistributor) {
      this.wiskDistributor = wiskDistributor

      if (wiskDistributor) {
        this.isPrivateDistributor = false
      }

      if (!this.distributor.id) {
        this.distributorTitle = wiskDistributor?.title || ''
      }

      if (this.$refs.inputGroup && !this.distributor.id) {
        if (wiskDistributor) {
          this.$refs.inputGroup.setOperation({ operation: { type: 'wisk_distributor_id', value: wiskDistributor.id }, operationTypes: { set: 'wisk_distributor_id', clear: 'wisk_distributor_id_clear' } })
          this.$refs.inputGroup.setOperation({ operation: { type: 'title', value: this.distributorTitle }, operationTypes: { set: 'title' } })
        } else {
          this.$refs.inputGroup.removeOperation({ type: 'wisk_distributor_id', operationTypes: { set: 'wisk_distributor_id', clear: 'wisk_distributor_id_clear' } })
          this.$refs.inputGroup.removeOperation({ type: 'title', operationTypes: { set: 'title' } })
        }
      }

      if (this.distributor && this.distributor.id && wiskDistributor) {
        this.onChange({ type: 'wisk_distributor_id', value: wiskDistributor.id })
      }
    },
    setValidState(errorCount) {
      this.valid = !errorCount
      this.$emit('validStateChanged', this.valid)
    },
    onTitleChange(title) {
      this.distributorTitle = title
      this.$refs.inputGroup.setOperation({ operation: { type: 'title', value: this.distributorTitle }, operationTypes: { set: 'title' } })
    },
    onChange(operation) {
      let operations = Array.isArray(operation) ? operation : [operation]

      if (this.distributor && this.distributor.id) {
        this.$emit('update:modelValue', operations)
      }
    },
    onChangeAll(operations) {
      this.operations = operations

      if (this.distributor && !this.distributor.id) {
        if (!this.isPrivateDistributor && this.distributorTitle && !this.operations.find(o => o.type === 'wisk_distributor_id')) {
          this.$emit('wiskDistributorOperations', [{ type: 'title', value: this.distributorTitle }, { type: 'country', value: this.venue.country }])
        } else {
          this.$emit('wiskDistributorOperations', [])
        }

        this.$emit('update:modelValue', operations)
      }
    }
  },
  created() {
    api.accountingDistributors().then(accountingDistributors => {
      this.accountingDistributors = accountingDistributors.sort((a, b) => compareStrings(a.title, b.title))
    })
    this.distributorTitle = this.distributor.title || ''

    if (!this.distributor.id || this.distributor.wisk_distributor) {
      this.isPrivateDistributor = false
    }
    console.log('this.distributor', this.distributor)
  },
  mounted() {
    setTimeout(() => {
      this.setValidState(0)
    }, 0)
  },
  watch: {
    isPrivateDistributor(isPrivateDistributor) {
      if (isPrivateDistributor) {
        this.$emit('wiskDistributorOperations', [])
      }
    },
    distributor(distributor) {
      if (distributor && distributor.id) {
        this.isPrivateDistributor = !distributor.wisk_distributor
      }
    }
  }
}
</script>
<style lang="scss"></style>
