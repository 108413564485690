<template>
  <wiskInput infoTooltipKey="5464b87a-34ab-4f6a-87fe-8dc22d718dfe" fitChildren :label="translations.txtVenueOperationsPaymentInfo" :modelValue="paymentInfoSummary" showPlainText disabled
    :fitChildrenCols="3" class="-light-control">
    <b-button type="button" variant="link" class="float-end h-100" size="sm" @click="modalVisible = true">
      <icon name="wisk-edit" scale=".7"></icon>&nbsp;
      {{ translations.txtGenericEdit }}
    </b-button>

    <wiskModal v-model="modalVisible" size="md" @ok="ok" :title="translations.txtVenueOperationsPaymentInfo"
      :okDisabled="!valid" :okText="translations.txtGenericSave">
      <wiskInputGroup @errorCountChanged="setValidState" style="min-height: 450px;" ref="inputGroup" @operationsChange="onChange">
        <wiskSelect infoTooltipKey="fbca3e53-e7de-417c-96d7-5ae08860ab4e" :label="translations.txtVenueOperationsSubscriptionStatus" v-model="subscriptionStatus" operation="subscription_status"
          :items="venueSubscriptionStatuses" :multiselectOptions="{}" class="mb-3" />

        <wiskSelect infoTooltipKey="5743d198-39a2-46dd-95cf-0a5dddee32e9" :label="translations.txtVenueOperationsPlan" v-model="selectedPlanId"
          :items="plans" :multiselectOptions="{ allowEmpty: false }" class="mb-3" :disabled="!planEditable" />

      </wiskInputGroup>
    </wiskModal>

    <confirm ref="confirmDialog" :okText="translations.txtGenericYes" :cancelText="translations.txtGenericNo" />

  </wiskInput>
</template>

<script>
import { /* mapActions, */ mapGetters, mapState } from 'vuex'
import merge from 'lodash.merge'
import get from 'lodash.get'
import api from '@/api'
import wiskInput from '@/components/common/WiskInput'
import wiskSelect from '@/components/common/WiskSelect'

const plan = { price: { yearly: { value: 0 }, monthly: { value: 0 } } }

export default {
  name: 'VenuePaymentInfo',
  emits: ['change'],
  components: { wiskSelect, wiskInput },
  props: {
    venue: { type: Object, required: true }
  },
  data() {
    return {
      modalVisible: false,
      plans: [],
      selectedPlanId: null,
      selectedPlan: merge({}, plan),
      subscription: {},
      operations: [],
      valid: false,
      subscriptionStatus: null,
      subscriptionType: 'yearly'
    }
  },
  computed: {
    ...mapGetters(['venueSubscriptionStatuses', 'currencies', 'billingTypes']),
    ...mapState(['user', 'translations']),
    paymentInfoSummary() {
      const selectedPlan = this.venue?.subscription?.selected_plan || this.selectedPlan,
        subscriptionStatus = this.venue?.subscription?.status || this.subscriptionStatus
      return (
        (selectedPlan &&
          `${this.translations.txtVenueOperationsPlan}: ${(selectedPlan?.type?.toUpperCase()) || ''}, ${this.translations.txtVenueOperationsSubscriptionStatus
          }: ${subscriptionStatus?.toUpperCase() || ''}`) || ''
      )
    },
    planEditable() {
      console.log('this.venue', JSON.parse(JSON.stringify(this.venue)))
      return this.subscriptionStatus === 'add_payment' || this.subscriptionStatus === 'active' || (this.user.god_mode && !this.venue.id)
    }
  },
  methods: {
    ok(askForConfirmation = true) {
      let subscription = merge({}, this.venue.subscription.selected_plan || {}, {
        type: this.selectedPlan?.type,
        billing_interval: this.subscriptionType,
        amount: {
          monthly: this.selectedPlan?.price?.monthly?.value,
          yearly: this.selectedPlan?.price?.yearly?.value
        }
      })
      if (this.planEditable) {
        const operationsEdited = this.operations.filter(o => o.type !== 'selected_plan')
        operationsEdited.push({ type: 'selected_plan', value: subscription })
        this.operations = operationsEdited
      }
      console.log(this.operations)

      if (askForConfirmation && this.$refs.confirmDialog && this.venue.id) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.operations.push({ type: 'send_subscription_added_email' })
            this.$emit('change', this.operations)
          },
          message: this.translations.confirmPaymentEditSendEmail,
          title: this.translations.confirmMovementEditSendEmailTitle,
          cancelCallback: () => {
            this.$emit('change', this.operations)
          }
        })
      } else {
        this.$emit('change', this.operations)
      }
    },
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    onChange(operations) {
      this.operations = [...operations]
    },
    initPlan() {
      if (this.venue && this.venue.subscription && this.venue.subscription.selected_plan) {
        let found = this.plans.find(p => p.type === this.venue.subscription.selected_plan.type) || {
          id: 1,
          price: { yearly: {}, monthly: {} },
          title: 'Basic',
          type: 'basic'
        },
          venuePlan = {
            price: {
              monthly: { value: get(this.venue, 'subscription.selected_plan.amount.monthly') },
              yearly: { value: get(this.venue, 'subscription.selected_plan.amount.yearly') }
            }
          }
        this.selectedPlanId = found && found.id
        this.selectedPlan = merge({}, found, venuePlan)
      } else if (!this.venue.id) {
        this.subscriptionStatus = 'active'
        this.selectedPlanId = 13
        this.selectedPlan = merge({}, plan, this.plans.find(p => p.id === 13) || {})
        const operationsEdited = this.operations.filter(o => o.type !== 'subscription_status')
        operationsEdited.push({
          value: this.subscriptionStatus,
          type: 'subscription_status',
        })
        this.operations = operationsEdited

        this.ok(false)
      }
    },
    getPlans() {
      api.venuePlans().then(response => {
        this.plans = response.map(p => ({ ...p, title: p.extended_title }))
        this.initPlan()
      })
    }
  },
  mounted() {
    this.getPlans()
  },
  watch: {
    modalVisible(modalVisible) {
      if (modalVisible) {
        this.getPlans()
      }
    },
    selectedPlanId(selectedPlanId) {
      let found = this.plans.find(p => p.id === selectedPlanId) || {}

      this.selectedPlan = merge({}, plan, found)
    },
    venue: {
      handler(venue) {
        if (venue && venue.subscription) {
          this.subscriptionStatus = venue.subscription.status

          if (venue.subscription.selected_plan && this.plans) {
            this.initPlan()
            this.subscriptionType = venue.subscription.selected_plan.billing_interval || 'yearly'
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss"></style>
