<template>
  <div v-if="item && item.item_id" style="min-height: 640px;">
    <b-form-radio-group size="sm" buttons v-model="currentTab" name="wisk-item-stats" button-variant="outline-primary" block class="mx-auto mb-3 w-100">
      <b-form-radio :value="0"> {{ translations.txtGenericStock }} </b-form-radio>
      <b-form-radio :value="1"> {{ translations.txtGenericConsumption }} </b-form-radio>
      <b-form-radio :value="3"> {{ translations.txtItemDetailsInventoryHistory }} </b-form-radio>
      <b-form-radio :value="6"> {{ translations.txtItemDetailsSoldSinceInventory }} </b-form-radio>
      <b-form-radio :value="7"> {{ translations.txtPOSItemsTitle }} </b-form-radio>
      <b-form-radio :value="8"> {{ translations.txtSubrecipesTitle }} </b-form-radio>
    </b-form-radio-group>
    <div v-if="currentTab === 0">
      <itemStatsGraph v-if="itemStats" :item="item" :translations="translations" :itemStats="itemStats" type="stock" />
    </div>
    <div v-if="currentTab === 1">
      <itemStatsGraph v-if="itemStats" :item="item" :translations="translations" :itemStats="itemStats" type="consumption" />
    </div>
    <div v-if="currentTab === 3">
      <itemInventoryHistory :item="item" style="min-height: 640px;" v-if="itemStats && item" />
    </div>
    <div v-if="currentTab === 6">
      <itemSales :item="item" v-if="item" />
    </div>
    <div v-if="currentTab === 7">
      <posItemsGrid :items="posItemsForBottle" v-if="item" />
    </div>
    <div v-if="currentTab === 8">
      <subrecipesGrid :items="subrecipesForBottle" v-if="item" :gridStyle="{ height: 'calc(100vh - 220px)' }" />
    </div>

    <wiskLoading :loading="!itemStats" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import get from 'lodash.get'
import api from '@/api'
import itemStatsGraph from '@/components/bottles/ItemStatsGraph'
import itemInventoryHistory from '@/components/bottles/ItemInventoryHistory'
import posItemsGrid from '@/components/posItems/POSItemsGrid'
import subrecipesGrid from '@/components/subrecipes/SubrecipesGrid'
import itemSales from '@/components/bottles/ItemSales'
// import { guid } from '@/modules/utils'

export default {
  name: 'ItemStats',
  components: { itemStatsGraph, itemInventoryHistory, itemSales, posItemsGrid, subrecipesGrid },
  props: {
    item: {
      type: Object
    },
    selectedTab: { type: Number, default: 0 }
  },
  data() {
    return {
      itemStats: null,
      itemId: 0,
      currentTab: 0,
      onDemandStateId: null
    }
  },
  computed: {
    ...mapState(['translations', 'subrecipes', 'posItemsById']),
    posItemsForBottle() {
      if (this.item && this.item.item_id && this.posItemsById) {
        return this.item.used_by.pos_items.map(id => this.posItemsById[id])
      }
      return []
    },
    ...mapState(['translations']),
    subrecipesForBottle() {
      if (this.item && this.item.item_id && this.subrecipes && this.subrecipes.length) {
        return this.subrecipes.filter(p => !!(p.ingredients && p.ingredients.find(i => i.item_id === this.item.item_id)))
      }
      return []
    }
  },
  methods: {
    ...mapActions(['setOnDemandState']),
    getStats() {
      if (this.item && this.item.item_id) {
        this.itemId = this.item.item_id
        this.itemStats = null

        api.itemStats(this.itemId).then(result => {
          this.itemStats = result
        })
      }
    }
  },
  // created() {
  //   let ids = get(this.item, 'used_by.pos_items', [])

  //   if (ids.length) {
  //     this.onDemandStateId = guid()
  //     this.setOnDemandState({ id: this.onDemandStateId, type: 'pos_item', payload: { ids } })
  //   }
  // },
  // beforeUnmount() {
  //   if (this.onDemandStateId) {
  //     this.setOnDemandState({ id: this.onDemandStateId, type: 'pos_item', REMOVE: true })
  //   }
  // },
  watch: {
    selectedTab: {
      handler(selectedTab) {
        setTimeout(() => {
          this.currentTab = selectedTab
        }, 0)
      },
      immediate: true
    },
    item: {
      handler: 'getStats',
      immediate: true
    }
  }
}
</script>
