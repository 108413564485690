<template>
  <b-container fluid class="px-0">

    <wiskItemsGrid class="px-0" :gridStyle="gridStyle" :columns="columns" :items="items" :options="options" resetRows :parentGridName="parentGridName"
      trackBy="combined_id" @gridApi="gridApiAvailable" :additionalHeaderCols="7" :additionalHeaderColsMobile="8" v-bind="$attrs"
      bottomAggregationRow :itemIdFilter="itemIdFilter" :key="gridKey" :header="{ hideSearch: !!itemIdFilter }" :customFilter="customFilterSelected"
      :defaultFilter="{ predicate: item => !!item, name: 'all', label: 'All' }" :loading="loading">

      <template v-slot:additional-controls>
        <b-button size="sm" class="ms-4 mt-1" variant="link" @click="getData">
          {{ translations.txtWiskGridRefreshData }}
        </b-button>
        <div>
          <wiskGridCustomFilterDropdown :customFilters="customFilters" v-model="customFilterSelected" />
        </div>
        <wiskInput infoTooltipKey="ea47a96b-23df-490d-8240-3749f3449a3f" class="flex-grow-1" :label="translations.txtConsumptionExpandBatches" v-model="expandBatches" inputType="checkbox" fitChildren :fitChildrenCols="3">
          <infoTooltip helpKey="inventoryDetailsExpandBatchesInfoMessage" />
        </wiskInput>
      </template>
    </wiskItemsGrid>

    <wiskModal :visible="!!movementsForItem" @hide="() => { movementsForItem = null; movementsForItemModalTitle = '' }" :title="movementsForItemModalTitle"
      size="lg" extraLarge hideOK hideHeaderExtra hideFooter>
      <div style="min-height: 400px;">
        <wiskGrid gridAutoHeight v-if="movementsForItem" :rowData="movementsForItem" :columnDefs="movementsGridColumns" :gridOptions="{}" trackBy="id"
          :excel="{ fileName: 'MovementsForItem' }" :key="movementsGridKey" parentGridName="MovementsForItem" />
      </div>
    </wiskModal>

    <wiskModal :visible="!!consumptionByLocation" @hide="() => { consumptionByLocation = null; consumptionByLocationModalTitle = '' }"
      :title="consumptionByLocationModalTitle" size="lg" extraLarge hideOK hideHeaderExtra hideFooter>
      <div style="min-height: 400px;">
        <wiskGrid gridAutoHeight v-if="consumptionByLocation" :rowData="consumptionByLocation" trackBy="id" :excel="{ fileName: 'ConsumptionByLocation' }"
          :columnDefs="byLocationGridColumns" :gridOptions="{}" bottomAggregationRow parentGridName="ConsumptionByLocation" />
      </div>
    </wiskModal>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import get from 'lodash.get'
import merge from 'lodash.merge'
import isEmpty from 'lodash.isempty'
import api from '@/api'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'
import wiskGrid from '@/components/grids/WiskGrid'
import { gridOptions, getItemsGridColumns, getMovementsGridColumns, getByLocationColumns } from './gridOptions'

export default {
  name: 'ConsumptionGrid',
  emits: ['gridApi', 'refresh'],
  components: { wiskItemsGrid, wiskGrid },
  props: {
    gridStyle: {
      type: Object,
      default: () => ({ height: 'calc(100vh - 120px)' })
    },
    itemIdFilter: Number,
    selectedRange: { type: Object, default: () => ({}) },
    parentGridName: { required: true, type: String },
    independentInventory: Boolean
  },
  data() {
    return {
      movementsGridColumns: null,
      byLocationGridColumns: null,
      columns: {},
      expandBatches: true,
      consumptionByLocation: null,
      consumptionByLocationModalTitle: '',
      movementsForItemModalTitle: '',
      movementsForItem: null,
      loading: false,
      selectedOrders: [],
      items: [],
      gridKey: 1,
      customFilterSelected: null,
      movementsGridKey: 1,
      selectedFilter: 'all',
      consumptionItems: []
    }
  },
  computed: {
    ...mapState(['user', 'locations', 'bottlesById', 'translations', 'itemPricesById', 'currentPermissionsByType', 'locationsById', 'venue']),
    options() {
      return merge({ rowSelection: { mode: 'multiRow', checkboxes: true } }, gridOptions)
    },
    customFilters() {
      return [
        {
          predicate: item => get(item, 'consumptionItem.consumption', 0) / get(item, 'volume', 1) < -0.02, //removing small differenced, this number is to be reviewed
          name: 'consumption_negative_value',
          label: this.translations.txtFilterNegativeConsumption
        },
        {
          predicate: item => !get(item, 'consumptionItem.from.units', 0) && get(item, 'consumptionItem.to.units', 0),
          name: 'new_items',
          label: this.translations.txtFilterConsumptionNewItems
        },
        {
          predicate: item => get(item, 'consumptionItem.from.units', 0) && !get(item, 'consumptionItem.to.units', 0),
          name: 'depleted_items',
          label: this.translations.txtFilterConsumptionDepletedItems
        }
      ]
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    gridApiAvailable(agGridApi) {
      this.$emit('gridApi', agGridApi)
    },
    setItems() {
      this.items = []
      if (this.bottlesById && !isEmpty(this.bottlesById) && this.consumptionItems && this.consumptionItems.length) {
        this.items = this.consumptionItems.map(consumptionItem =>
          merge(
            {},
            this.bottlesById[consumptionItem.item_id] || {},
            { consumptionItem },
            { location: consumptionItem.location || (this.bottlesById[consumptionItem.item_id] || {}).location },
            {
              combined_id: consumptionItem.item_id + (consumptionItem.location ? '_' + consumptionItem.location.id : ''),
              price_per_unit: (this.itemPricesById[consumptionItem.item_id] || {}).price_per_unit
            }
          )
        )

        //this.gridKey++
      }
      this.loading = false
    },
    getData() {
      return new Promise((resolve, reject) => {
        if (this.selectedRange && this.selectedRange.flatten_by) {
          this.loading = true
          api
            .consumption({ ...this.selectedRange, item_id: this.itemIdFilter, expand_batches: this.expandBatches, is_independent_inventory: this.independentInventory })
            .then(data => {
              this.createColumnDefinitions()
              this.consumptionItems = data
              this.setItems()
              resolve()
            })
            .catch(e => {
              this.loading = false
              reject(e)
            })
        } else {
          reject()
        }
      })
    },
    openInventory(id, locationId, itemIdFilter) {
      this.setGlobalAction({
        type: 'inventoryDetails',
        action: { id, viewMode: 'item_location', locationId, itemIdFilter, onChange: this.refresh, expandBatches: this.expandBatches }
      })
    },
    refresh() {
      setTimeout(() => {
        this.$emit('refresh')
      }, 0)
    },
    closeMovementsForItem() {
      this.getData().then(() => {
        this.movementsForItem = null
        this.movementsForItemModalTitle = ''
        this.refresh()
      })
    },
    openMovementsForItem(data) {
      let bottle = this.bottlesById[data.item_id] || {}

      this.movementsForItemModalTitle = this.translations.translate('tplConsumptionMovementsForItem', {
        '{a}': bottle.title + bottle.titleSuffix,
        '{b}': (data.location && data.location.title) || '' //TODO: Find out where the data.location is missing
      })
      data.movements.details.forEach(movement => {
        movement.date = new Date(movement.date)
        movement.units = (movement.ml / data.volume).toFixed(2)
      })
      this.movementsForItem = data.movements.details.map(m => {
        m.item_id = data.item_id
        return m
      })
    },
    openByLocation(data) {
      let bottle = this.bottlesById[data.item_id] || {}

      this.consumptionByLocationModalTitle = this.translations.translate('tplConsumptionByLocationForItem', {
        '{a}': bottle.title + bottle.titleSuffix
      })

      this.consumptionByLocation = data.by_location.map(z => ({ ...z, volume: data.volume, item_id: data.item_id, id: z.location.id }))
    },
    createColumnDefinitions() {
      this.columns = getItemsGridColumns({
        translations: this.translations,
        showMovements: this.openMovementsForItem,
        showByLocation: this.openByLocation,
        hasLocation: this.selectedRange.flatten_by === 'item_location',
        openInventory: this.openInventory,
        defaultUMVolume: this.venue.default_um_volume,
        defaultUMWeight: this.venue.default_um_weight
      })

      this.movementsGridColumns = getMovementsGridColumns({
        translations: this.translations,
        setGlobalAction: this.setGlobalAction,
        closeMovementsForItem: this.closeMovementsForItem,
        currentPermissionsByType: this.currentPermissionsByType
      })

      this.byLocationGridColumns = getByLocationColumns({
        showMovements: this.openMovementsForItem,
        openInventory: this.openInventory,
        translations: this.translations
      })
    }
  },
  watch: {
    selectedRange: {
      handler: 'getData',
      immediate: true
    },
    expandBatches: 'getData',
    bottlesById: {
      immediate: true,
      handler: 'setItems'
    }
  }
}
</script>

<style lang="scss"></style>
