import en from './en'

const translations = {
    ...en, // default language
    translate(translationKey, replacements = {}) {
        let translated = ''
        if (translationKey.indexOf('.') > 4) {
          let split = translationKey.split('.'),
            groupObject = this[split[0]]

          translated = (groupObject && groupObject[split[1]]) || ''
        } else {
          translated = this[translationKey] || ''
        }

        Object.keys(replacements).forEach(key => {
          translated = translated.replace(new RegExp(key, 'g'), replacements[key])
        })
        return translated
    }
}

export default translations