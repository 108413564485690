import { markRaw } from 'vue'
import merge from 'lodash.merge'
import cloneDeep from 'lodash.clonedeep'
// import { detailedDiff } from 'deep-object-diff'
import equal from 'fast-deep-equal/es6'
import { arrayToObjectById, compareNumbers } from '@/modules/utils'
import initialState from './state'

const mutations = {
  mutateResetState: state => {
    Object.assign(state, merge({}, initialState))
  },
  mutatePageViewPermissions(state, pageViewPermissions) {
    state.pageViewPermissions = pageViewPermissions
  },
  mutateOnlineState(state, payload) {
    state.online = !!payload
    console.info('state.online', state.online)
  },
  mutateWindowResizeKey(state, windowResizeKey) {
    state.windowResizeKey = windowResizeKey
  },
  mutateDeviceInfo(state, deviceInfo) {
    state.deviceInfo = deviceInfo
  },
  mutateWaitingForVenue(state, payload) {
    state.waitingForVenue = !!payload
  },
  mutateSignupFlow(state, payload) {
    state.signupFlow = payload
  },
  mutateDistributorPrices: (state, payload) => {
    let distributorPricesById = {}

    state.distributorPrices = payload || []
    state.distributorPricesById = {}

    state.distributorPrices.forEach(item => {
      distributorPricesById[item.distributor_id] = {}

      item.item_prices.forEach(bottle => {
        if (bottle) {
          distributorPricesById[item.distributor_id][bottle.item_distributor_id] = bottle.price
        }
      })
    })

    state.distributorPricesById = distributorPricesById
  },
  mutateDistributorCredentials(state, credentials) {
    state.distributorCredentials = credentials
    state.distributorCredentialsByDistributorId = arrayToObjectById(credentials, 'distributor_id')
  },
  mutateDistributorCredential(state, credential) {
    let foundIndex = state.distributorCredentials.findIndex(m => m.id === credential.id),
      index = foundIndex + 1 ? foundIndex : state.distributorCredentials.length

    if (credential.REMOVE) {
      state.distributorCredentials.splice(index, 1)
    } else {
      state.distributorCredentials[index] = credential
    }
    state.distributorCredentialsByDistributorId = arrayToObjectById(state.distributorCredentials, 'distributor_id')
  },
  mutateInfoTooltipsById: (state, infoTooltipsById) => {
    state.infoTooltipsById = infoTooltipsById || {}
  },
  mutateInfoTooltipsDirty: (state, infoTooltipsDirty) => {
    state.infoTooltipsDirty = infoTooltipsDirty
  },
  mutateGlobalOptions: (state, options) => {
    options = options || {}

    if (Array.isArray(options.measurements)) {
      const mergedUnits = options.measurements.flatMap(item => item.units_of_measurements),
        byId = arrayToObjectById(mergedUnits, 'id')

      window.WiskGlobals = window.WiskGlobals || {}
      window.WiskGlobals.measurementsById = byId
    }
    state.options = options
  },
  mutatePermissions: (state, permissions) => {
    state.permissions = permissions || []
    state.permissionsById = arrayToObjectById(permissions || [])
  },
  mutateCurrentPermissions: (state, permissionIds) => {
    let permissions = permissionIds.map(id => {
      let permission = state.permissionsById[id]
      return permission
    })

    state.currentPermissionsByType = arrayToObjectById(permissions, 'key')

    console.log('currentPermissionsByType', state.currentPermissionsByType)
  },
  mutateCurrentFeatures: (state, currentPlanFeaturesByType) => {
    state.currentPlanFeaturesByType = currentPlanFeaturesByType
  },
  mutateGridViews: (state, views) => {
    state.gridViews = views
  },
  mutateGridView(state, view) {
    let foundIndex = state.gridViews.findIndex(m => m.id === view.id),
      index = foundIndex + 1 ? foundIndex : state.gridViews.length

    if (view.REMOVE) {
      state.gridViews.splice(index, 1)
    } else {
      state.gridViews[index] = view
    }
    state.gridViews = [...state.gridViews]
  },
  mutateGridViewsCore: (state, views) => {
    state.gridViewsCore = views
  },
  mutateGridViewCore(state, view) {
    let foundIndex = state.gridViewsCore.findIndex(m => m.id === view.id),
      index = foundIndex + 1 ? foundIndex : state.gridViewsCore.length

    if (view.REMOVE) {
      state.gridViewsCore.splice(index, 1)
    } else {
      state.gridViewsCore[index] = view
    }
    state.gridViewsCore = [...state.gridViewsCore]
  },
  mutateSubrecipes(state, subrecipes) {
    state.subrecipes = subrecipes
    state.subrecipesById = arrayToObjectById(subrecipes)
    state.subrecipesByInventoryItemId = arrayToObjectById(
      subrecipes.filter(s => s.inventory_item_id),
      'inventory_item_id'
    )
  },
  mutateSubrecipe(state, subrecipe) {
    let foundIndex = state.subrecipes.findIndex(m => m.id === subrecipe.id),
      index = foundIndex + 1 ? foundIndex : state.subrecipes.length

    if (subrecipe.REMOVE) {
      state.subrecipes.splice(index, 1)
      delete state.subrecipesById[subrecipe.id]
    } else {
      state.subrecipes[index] = subrecipe
      state.subrecipesById[subrecipe.id] = subrecipe
    }

    state.subrecipesById = { ...state.subrecipesById }
  },
  mutateSubrecipeCosts(state, payload) {
    state.subrecipeCosts = payload
    state.subrecipeCostById = arrayToObjectById(payload)
  },
  mutateSubrecipeCost(state, item) {
    let foundIndex = state.subrecipeCosts.findIndex(b => b.id === item.id),
      index = foundIndex + 1 ? foundIndex : state.subrecipeCosts.length

    if (item.REMOVE) {
      state.subrecipeCosts.splice(index, 1)
      delete state.subrecipeCostById[item.id]
    } else {
      state.subrecipeCosts[index] = item
      state.subrecipeCostById[item.id] = item
    }
    state.subrecipeCostById = { ...state.subrecipeCostById }
  },
  mutateVenueTransfers(state, venueTransfers) {
    state.venueTransfers = venueTransfers
    state.venueTransfersById = arrayToObjectById(venueTransfers)
  },
  mutateVenueTransfer(state, venueTransfer) {
    let foundIndex = state.venueTransfers.findIndex(m => m.id === venueTransfer.id),
      index = foundIndex + 1 ? foundIndex : state.venueTransfers.length

    if (venueTransfer.REMOVE) {
      state.venueTransfers.splice(index, 1)
      delete state.venueTransfersById[venueTransfer.id]
    } else {
      state.venueTransfers[index] = venueTransfer
      state.venueTransfersById[venueTransfer.id] = venueTransfer
    }

    state.venueTransfersById = { ...state.venueTransfersById }
  },
  mutatePOSIntegrations(state, posIntegrations) {
    state.posIntegrations = posIntegrations
  },
  mutatePOSIntegration(state, posIntegration) {
    let foundIndex = state.posIntegrations.findIndex(m => m.id === posIntegration.id),
      index = foundIndex + 1 ? foundIndex : state.posIntegrations.length

    if (posIntegration.REMOVE) {
      state.posIntegrations.splice(index, 1)
    } else {
      state.posIntegrations[index] = posIntegration
    }
  },
  mutateRoles: (state, roles) => {
    state.roles = roles
    state.rolesById = arrayToObjectById(roles)
  },
  mutateRole(state, role) {
    let foundIndex = state.roles.findIndex(b => b.id === role.id),
      index = foundIndex + 1 ? foundIndex : state.roles.length

    if (role.REMOVE) {
      state.roles.splice(index, 1)
      delete state.rolesById[role.id]
    } else {
      state.roles[index] = role
      state.rolesById[role.id] = role
    }
  },
  mutateCurrentUser(state, user) {
    user.god_mode = user.god_mode || user.is_super_user
    console.log('mutateCurrentUser', user)

    if (user.god_mode && !user.user_venues?.length) {
      alert('no venues for user (user.user_venues is empty), some features might not work')
    }
    state.user = user
  },
  mutateFirebaseUser(state, user) {
    state.firebaseUser = user
  },
  mutateSidebarMinimized(state, sidebarMinimized) {
    state.sidebarMinimized = sidebarMinimized
  },
  mutateNotification(state, notification) {
    state.notification = notification
  },
  mutateOrders(state, orders) {
    state.orders = orders
  },
  mutateDraftInvoices(state, draftInvoices) {
    if (!equal(state.draftInvoices, draftInvoices)) {
      state.draftInvoices = draftInvoices
      state.draftInvoicesById = arrayToObjectById(draftInvoices)
    }
  },
  mutateDraftInvoice(state, draftInvoice) {
    let foundIndex = state.draftInvoices.findIndex(b => b.id === draftInvoice.id),
      index = foundIndex + 1 ? foundIndex : state.draftInvoices.length,
      hasChanged = !equal(state.draftInvoices[index], draftInvoice)

    console.log('mutateDraftInvoice hasChanged: ', hasChanged, draftInvoice)

    if (hasChanged) {
      if (draftInvoice.REMOVE) {
        state.draftInvoices.splice(index, 1)
        delete state.draftInvoicesById[draftInvoice.id]
      } else {
        state.draftInvoices[index] = draftInvoice
        state.draftInvoicesById[draftInvoice.id] = draftInvoice
      }
      state.draftInvoicesById = { ...state.draftInvoicesById }
    }
  },
  mutateDraftInvoiceLines(state, draftInvoiceLines) {
    console.log('mutateDraftInvoiceLines', draftInvoiceLines.length)

    let draftInvoiceLinesByDraftInvoiceId = {}

    draftInvoiceLines.forEach(line => {
      draftInvoiceLinesByDraftInvoiceId[line.draft_invoice_id] = draftInvoiceLinesByDraftInvoiceId[line.draft_invoice_id] || []
      draftInvoiceLinesByDraftInvoiceId[line.draft_invoice_id].push(line)
    })

    state.draftInvoiceLinesByDraftInvoiceId = draftInvoiceLinesByDraftInvoiceId
  },
  mutateDraftInvoiceLine(state, draftInvoiceLine) {
    state.draftInvoiceLinesByDraftInvoiceId = state.draftInvoiceLinesByDraftInvoiceId || {}
    state.draftInvoiceLinesByDraftInvoiceId[draftInvoiceLine.draft_invoice_id] = state.draftInvoiceLinesByDraftInvoiceId[draftInvoiceLine.draft_invoice_id] || []

    let foundIndex = state.draftInvoiceLinesByDraftInvoiceId[draftInvoiceLine.draft_invoice_id].findIndex(b => b.id === draftInvoiceLine.id),
      index = foundIndex + 1 ? foundIndex : state.draftInvoiceLinesByDraftInvoiceId[draftInvoiceLine.draft_invoice_id].length,
      hasChanged = !equal(state.draftInvoiceLinesByDraftInvoiceId[draftInvoiceLine.draft_invoice_id][index], draftInvoiceLine)

    console.log('mutateDraftInvoiceLine hasChanged: ', hasChanged, draftInvoiceLine)

    if (hasChanged) {
      if (draftInvoiceLine.REMOVE) {
        state.draftInvoiceLinesByDraftInvoiceId[draftInvoiceLine.draft_invoice_id].splice(index, 1)
      } else {
        state.draftInvoiceLinesByDraftInvoiceId[draftInvoiceLine.draft_invoice_id][index] = draftInvoiceLine
      }

      state.draftInvoiceLinesByDraftInvoiceId = { ...state.draftInvoiceLinesByDraftInvoiceId }
    }
  },
  mutateDraftInvoiceExtraLines(state, draftInvoiceExtraLines) {
    state.draftInvoiceExtraLines = draftInvoiceExtraLines

    let draftInvoiceExtraLinesByDraftInvoiceId = {}
    draftInvoiceExtraLines.forEach(line => {
      draftInvoiceExtraLinesByDraftInvoiceId[line.draft_invoice_id] = draftInvoiceExtraLinesByDraftInvoiceId[line.draft_invoice_id] || []
      draftInvoiceExtraLinesByDraftInvoiceId[line.draft_invoice_id].push(line)
    })
    state.draftInvoiceExtraLinesByDraftInvoiceId = draftInvoiceExtraLinesByDraftInvoiceId
  },
  mutateDraftInvoiceExtraLine(state, draftInvoiceExtraLine) {
    let foundIndex = state.draftInvoiceExtraLines.findIndex(b => b.id === draftInvoiceExtraLine.id),
      index = foundIndex + 1 ? foundIndex : state.draftInvoiceExtraLines.length

    if (draftInvoiceExtraLine.REMOVE) {
      state.draftInvoiceExtraLines.splice(index, 1)
    } else {
      state.draftInvoiceExtraLines[index] = draftInvoiceExtraLine
    }

    let draftInvoiceExtraLinesByDraftInvoiceId = {}
    state.draftInvoiceExtraLines.forEach(line => {
      draftInvoiceExtraLinesByDraftInvoiceId[line.draft_invoice_id] = draftInvoiceExtraLinesByDraftInvoiceId[line.draft_invoice_id] || []
      draftInvoiceExtraLinesByDraftInvoiceId[line.draft_invoice_id].push(line)
    })
    state.draftInvoiceExtraLinesByDraftInvoiceId = draftInvoiceExtraLinesByDraftInvoiceId
  },
  mutateMovementExtraLineReasons(state, reasons) {
    state.movementExtraLineReasons = reasons
    state.movementExtraLineReasonsById = arrayToObjectById(reasons)
  },
  mutateMovementAdjustmentReasons(state, reasons) {
    state.movementAdjustmentReasons = reasons
    state.movementAdjustmentReasonsById = arrayToObjectById(reasons)
  },
  mutateMovements(state, movements) {
    // movements and movementsById are not good names because they are not movements, they are just high level movement data
    state.movements = movements
    state.movementsById = arrayToObjectById(movements)
  },
  mutateMovement(state, movement) {
    let foundIndex = state.movements.findIndex(m => m.id === movement.id),
      index = foundIndex + 1 ? foundIndex : state.movements.length

    state.movements[index] = movement
    state.movementsById = arrayToObjectById(state.movements)
  },
  mutateLocations(state, locations) {
    state.locations = locations.sort((a, b) => compareNumbers(a.sort_order, b.sort_order))
    state.locationsById = arrayToObjectById(locations)
  },
  mutateLocation(state, location) {
    let foundIndex = state.locations.findIndex(b => b.id === location.id),
      index = foundIndex + 1 ? foundIndex : state.locations.length

    if (location.REMOVE) {
      state.locations.splice(index, 1)
      delete state.locationsById[location.id]
    } else {
      state.locations[index] = location
      state.locationsById[location.id] = location
    }
    state.locations.sort((a, b) => compareNumbers(a.sort_order, b.sort_order))
    state.locations = [...state.locations]
  },
  mutateTaxes(state, taxes) {
    state.taxes = taxes
    state.taxesById = arrayToObjectById(taxes)
  },
  mutateTax(state, tax) {
    let foundIndex = state.taxes.findIndex(b => b.id === tax.id),
      index = foundIndex + 1 ? foundIndex : state.taxes.length

    if (tax.REMOVE) {
      state.taxes.splice(index, 1)
      delete state.taxesById[tax.id]
    } else {
      state.taxes[index] = tax
      state.taxesById[tax.id] = tax
    }
  },
  mutateInventories(state, inventories) {
    state.inventories = inventories
  },
  mutateInventoriesHighLevelLoadAllComplete(state, payload) {
    state.inventoriesHighLevelLoadAllComplete = payload
  },
  mutateInventory(state, inventory) {
    let foundIndex = state.inventories.findIndex(m => m.id === inventory.id),
      index = foundIndex + 1 ? foundIndex : state.inventories.length

    state.inventories[index] = inventory
  },
  mutateAccountingVenueClasses(state, accountingVenueClasses) {
    state.accountingVenueClasses = accountingVenueClasses
    state.accountingVenueClassesById = arrayToObjectById(accountingVenueClasses)
  },
  mutateAccountingVenueClass(state, accountingVenueClass) {
    let foundIndex = state.accountingVenueClasses.findIndex(b => b.id === accountingVenueClass.id),
      index = foundIndex + 1 ? foundIndex : state.accountingVenueClasses.length

    if (accountingVenueClass.REMOVE) {
      state.accountingVenueClasses.splice(index, 1)
      delete state.accountingVenueClassesById[accountingVenueClass.id]
    } else {
      state.accountingVenueClasses[index] = accountingVenueClass
      state.accountingVenueClassesById[accountingVenueClass.id] = accountingVenueClass
    }
    state.accountingVenueClasses = [...state.accountingVenueClasses]
    state.accountingVenueClassesById = { ...state.accountingVenueClassesById }
  },
  mutateDashboardRealtimeData(state, data) {
    if (data && data.items) {
      state.dashboardRealtimeData = arrayToObjectById(
        (data.items || []).map(i => ({ type: i.type, ...i.value })),
        'type'
      )
    } else {
      state.dashboardRealtimeData = {}
    }
  },
  mutateInventoryLocations(state, inventoryLocations) {
    state.inventoryLocations = inventoryLocations

    let inventoryLocationsByInventoryId = {}
    inventoryLocations.forEach(location => {
      location.inventory_id = location.inventory_id || location.venue_inventory_id
      inventoryLocationsByInventoryId[location.inventory_id] = inventoryLocationsByInventoryId[location.inventory_id] || []
      inventoryLocationsByInventoryId[location.inventory_id].push(location)
    })
    state.inventoryLocationsByInventoryId = inventoryLocationsByInventoryId
  },
  mutateInventoryLocation(state, inventoryLocation) {
    let foundIndex = state.inventoryLocations.findIndex(b => b.id === inventoryLocation.id),
      index = foundIndex + 1 ? foundIndex : state.inventoryLocations.length

    if (inventoryLocation.REMOVE) {
      state.inventoryLocations.splice(index, 1)
    } else {
      state.inventoryLocations[index] = inventoryLocation
    }

    let inventoryLocationsByInventoryId = {}
    state.inventoryLocations.forEach(location => {
      location.inventory_id = location.inventory_id || location.venue_inventory_id
      inventoryLocationsByInventoryId[location.inventory_id] = inventoryLocationsByInventoryId[location.inventory_id] || []
      inventoryLocationsByInventoryId[location.inventory_id].push(location)
    })
    state.inventoryLocationsByInventoryId = inventoryLocationsByInventoryId
  },
  mutateInventoryEntries(state, entries) {
    console.log('deprecated mutation, to be removed!!! - mutateInventoryEntries', entries.length)
  },
  mutateInventoryEntry(state, inventoryEntry) {
    console.log('deprecated mutation, to be removed!!! - mutateInventoryEntry', inventoryEntry)
  },
  mutateOrderGuideVariantIds(state, orderGuides = []) {
    state.orderGuideRawData = markRaw(orderGuides)
    state.orderGuideVariantIdsMap = arrayToObjectById(orderGuides, 'item_distributor_id')
  },
  mutateOrderGuideVariantId(state, orderGuide) {
    let indexOfItem = state.orderGuideRawData.findIndex(i => i.id === orderGuide.id),
      index = indexOfItem + 1 ? indexOfItem : state.orderGuideRawData.length

    if (orderGuide.REMOVE) {
      state.orderGuideRawData.splice(index, 1)
    } else {
      state.orderGuideRawData[index] = markRaw(orderGuide)
    }
    state.orderGuideVariantIdsMap = arrayToObjectById(state.orderGuideRawData, 'item_distributor_id')
  },
  mutateRecentInventories(state, recentInventories) {
    state.recentInventoriesById = arrayToObjectById(recentInventories) || {}
  },
  mutateRecentInventory(state, recentInventory) {
    if (recentInventory.REMOVE) {
      delete state.recentInventoriesById[recentInventory.id]
    } else {
      state.recentInventoriesById[recentInventory.id] = recentInventory
    }
    state.recentInventoriesById = { ...state.recentInventoriesById }
  },
  mutateCountries(state, countries) {
    state.countries = countries
  },
  mutateOrder(state, order) {
    let foundIndex = state.orders.findIndex(o => o.id === order.id),
      index = foundIndex + 1 ? foundIndex : state.orders.length

    state.orders[index] = order
  },
  mutateDashboardData(state, dashboardData) {
    state.dashboardData = dashboardData
  },
  mutateShoppingCart(state, shoppingCart) {
    state.shoppingCart = shoppingCart
    state.shoppingCartByItemId = arrayToObjectById(shoppingCart, 'item_id')
    state.shoppingCartByVariantId = arrayToObjectById(shoppingCart, 'item_distributor_id')
  },
  mutatePOSItemsExtraData(state, posItemsExtraData) {
    state.posItemsExtraDataById = arrayToObjectById(posItemsExtraData, 'pos_item_id')
  },
  mutatePOSItems(state, posItemsList) {
    // console.log('mutatePOSItems', posItemsList.length)

    // Separate Draft POS items
    const posItems = {},
      draftPOSItems = {}

    posItemsList.forEach(item => {
      if (item.is_draft_pos_item) {
        if (!draftPOSItems[item.id] && !posItems[item.id]) {
          draftPOSItems[item.id] = item
        }
      } else if (!posItems[item.id] && !draftPOSItems[item.id]) {
        posItems[item.id] = item
      }
    })

    state.posItems = Object.values(posItems)
    state.draftPOSItems = Object.values(draftPOSItems)
    // This doesn't need to be separated because it is accessed by id
    state.posItemsById = arrayToObjectById(posItemsList)
  },
  mutatePOSItem(state, updatedPOSItem) {
    // console.log('mutatePOSItem', updatedPOSItem)
    let indexOfItem = state.posItems.findIndex(i => i.id === updatedPOSItem.id),
      indexOfItemInDrafts = state.draftPOSItems.findIndex(i => i.id === updatedPOSItem.id),
      index = indexOfItem + 1 ? indexOfItem : state.posItems.length,
      indexInDrafts = indexOfItemInDrafts + 1 ? indexOfItemInDrafts : state.draftPOSItems.length
    if (updatedPOSItem.REMOVE) {
      if (indexOfItem !== -1) {
        state.posItems.splice(index, 1)
      } else if (indexOfItemInDrafts !== -1) {
        state.draftPOSItems.splice(indexInDrafts, 1)
      }
      delete state.posItemsById[updatedPOSItem.id]
    } else {
      if (updatedPOSItem.is_draft_pos_item) {
        if (indexOfItem !== -1) {
          state.posItems.splice(index, 1)
        }
        state.draftPOSItems[indexInDrafts] = updatedPOSItem
      } else {
        if (indexOfItemInDrafts !== -1) {
          state.draftPOSItems.splice(indexInDrafts, 1)
        }
        state.posItems[index] = updatedPOSItem
      }
      state.posItemsById[updatedPOSItem.id] = updatedPOSItem
    }
    state.posItemsById = { ...state.posItemsById }
  },
  mutatePOSItemCosts(state, payload) {
    // console.log('mutatePOSItemCosts', payload.length)
    state.posItemCostsById = arrayToObjectById(payload.map(item => markRaw(item)))
  },
  mutatePOSItemCost(state, item) {
    // console.log('mutatePOSItemCost', item)
    if (item.REMOVE) {
      delete state.posItemCostsById[item.id]
    } else {
      state.posItemCostsById[item.id] = markRaw(item)
    }
    state.posItemCostsById = { ...state.posItemCostsById }
  },
  mutatePOSModifiers(state, posModifiers) {
    state.posModifiers = posModifiers
    state.posModifiersById = arrayToObjectById(posModifiers)
  },
  mutatePOSModifier(state, posModifier) {
    let foundIndex = state.posModifiers.findIndex(m => m.id === posModifier.id),
      index = foundIndex + 1 ? foundIndex : state.posModifiers.length

    if (posModifier.REMOVE) {
      state.posModifiers.splice(index, 1)
      delete state.posModifiersById[posModifier.id]
    } else {
      state.posModifiers[index] = posModifier
      state.posModifiersById[posModifier.id] = posModifier
    }
    state.posModifiersById = { ...state.posModifiersById }
  },
  mutateRevenueCenters(state, payload) {
    state.revenueCenters = payload
    state.revenueCentersById = arrayToObjectById(payload)
  },
  mutateRevenueCenter(state, item) {
    let foundIndex = state.revenueCenters.findIndex(b => b.id === item.id),
      index = foundIndex + 1 ? foundIndex : state.revenueCenters.length

    if (item.REMOVE) {
      state.revenueCenters.splice(index, 1)
      delete state.revenueCentersById[item.id]
    } else {
      state.revenueCenters[index] = item
      state.revenueCentersById[item.id] = item
    }
    state.revenueCentersById = { ...state.revenueCentersById }
  },
  mutateServingSizes(state, servingSizes = []) {
    let custom = [
      {
        title: 'Custom',
        type: 'custom',
        id: -2,
        measurement: { type: 'unit', unit_of_measurement: 'unit', quantity: 1 }
      }
    ]

    servingSizes.forEach(s => {
      delete s.quantity
      delete s.unit_of_measurement
    })
    servingSizes = servingSizes.filter(s => s.id !== -2)
    state.servingSizes = [...custom, ...servingSizes]
  },
  mutateServingSize(state, servingSize) {
    let indexOfItem = state.servingSizes.findIndex(i => i.id === servingSize.id),
      index = indexOfItem + 1 ? indexOfItem : state.servingSizes.length

    delete servingSize.quantity
    delete servingSize.unit_of_measurement

    if (servingSize.REMOVE) {
      state.servingSizes.splice(index, 1)
    } else {
      state.servingSizes[index] = servingSize
    }
  },
  mutateVenueDummyMutation() {},
  mutateVenueStats(state, stats) {
    if (!equal(state.venueStats, stats)) {
      // console.log('detailedDiff venue', detailedDiff(state.venueStats, vstatsenue))
      state.venueStats = stats
    }
  },
  mutateCurrentVenue(state, venue) {
    if (!equal(state.venue, venue)) {
      // console.log('detailedDiff venue', detailedDiff(state.venue, venue))
      state.venue = venue
    }
  },
  mutateVenues(state, venues) {
    state.venues = venues
  },
  mutateVenuesHighLevel(state, venuesHighLevel) {
    if (Array.isArray(venuesHighLevel)) {
      state.venuesHighLevel = venuesHighLevel
      state.venuesHighLevelById = arrayToObjectById(venuesHighLevel)

      let map = {}
      for (let i = 0; i < state.venuesHighLevel.length; i++) {
        map[state.venuesHighLevel[i].id] = state.venuesHighLevel[i].roles
      }
      state.rolesByVenueId = map
    }
  },
  mutateVenue(state, venue) {
    if (venue) {
      let indexOfItem = state.venues.findIndex(i => i && i.id === venue.id),
        index = indexOfItem + 1 ? indexOfItem : state.venues.length

      state.venues[index] = venue
    }
  },
  mutateLoadingScreenVisibility(state, visible) {
    state.loadingScreenVisible = visible
  },
  mutateInitialDBLoadingProgress(state, payload) {
    state.initialDBLoadingProgress = payload
  },
  mutateInitialDataLoadComplete(state, payload) {
    state.firestoreInitialLoadComplete = payload
  },
  mutateDistributors(state, distributors) {
    state.distributors = distributors
    state.distributorsById = arrayToObjectById(distributors)
  },
  mutateDistributor(state, distributor) {
    let foundIndex = state.distributors.findIndex(b => b.id === distributor.id),
      index = foundIndex + 1 ? foundIndex : state.distributors.length

    if (distributor.REMOVE) {
      state.distributors.splice(index, 1)
      delete state.distributorsById[distributor.id]
    } else {
      state.distributors[index] = distributor
      state.distributorsById[distributor.id] = distributor
    }
    state.distributors = [...state.distributors]
    state.distributorsById = { ...state.distributorsById }
  },
  mutateFamilies(state, families) {
    state.families = families
    state.familiesById = arrayToObjectById(families)
  },
  mutateFamily(state, family) {
    let foundIndex = state.families.findIndex(b => b.id === family.id),
      index = foundIndex + 1 ? foundIndex : state.families.length

    if (family.REMOVE) {
      state.families.splice(index, 1)
      delete state.familiesById[family.id]
    } else {
      state.families[index] = family
      state.familiesById[family.id] = family
    }
    state.familiesById = { ...state.familiesById }
  },
  mutateCategories(state, categories) {
    state.categories = categories
    state.categoriesById = arrayToObjectById(categories)
  },
  mutateCategory(state, category) {
    let foundIndex = state.categories.findIndex(b => b.id === category.id),
      index = foundIndex + 1 ? foundIndex : state.categories.length

    if (category.REMOVE) {
      state.categories.splice(index, 1)
      delete state.categoriesById[category.id]
    } else {
      state.categories[index] = category
      state.categoriesById[category.id] = category
    }
    state.categoriesById = { ...state.categoriesById }
  },
  mutateGLAccounts(state, glAccounts) {
    state.glAccounts = glAccounts
    state.glAccountsById = arrayToObjectById(glAccounts)
  },
  mutateGLAccount(state, glAccount) {
    let foundIndex = state.glAccounts.findIndex(b => b.id === glAccount.id),
      index = foundIndex + 1 ? foundIndex : state.glAccounts.length

    if (glAccount.REMOVE) {
      state.glAccounts.splice(index, 1)
      delete state.glAccountsById[glAccount.id]
    } else {
      state.glAccounts[index] = glAccount
      state.glAccountsById[glAccount.id] = glAccount
    }
    state.glAccountsById = { ...state.glAccountsById }
  },
  mutateCustomFieldsByTarget(state, customFieldsByTarget) {
    state.customFieldsByTarget = customFieldsByTarget
  },
  mutateCustomFieldsByTargetActive(state, customFieldsByTargetActive) {
    state.customFieldsByTargetActive = customFieldsByTargetActive
  },
  mutateCustomFields(state, customFields) {
    state.customFields = customFields
    state.customFieldsById = arrayToObjectById(customFields)
  },
  mutateCustomField(state, customField) {
    let foundIndex = state.customFields.findIndex(b => b.id === customField.id),
      index = foundIndex + 1 ? foundIndex : state.customFields.length

    if (customField.REMOVE) {
      state.customFields.splice(index, 1)
      delete state.customFieldsById[customField.id]
    } else {
      state.customFields[index] = customField
      state.customFieldsById[customField.id] = customField
    }
  },
  mutateAllergens(state, payload) {
    state.allergens = payload
    state.allergensById = arrayToObjectById(payload)
  },
  mutateAllergen(state, allergen) {
    let foundIndex = state.allergens.findIndex(b => b.id === allergen.id),
      index = foundIndex + 1 ? foundIndex : state.allergens.length

    if (allergen.REMOVE) {
      state.allergens.splice(index, 1)
      delete state.allergensById[allergen.id]
    } else {
      state.allergens[index] = allergen
      state.allergensById[allergen.id] = allergen
    }
  },
  mutateActiveItemIds(state, payload) {
    state.activeItemIds = payload.filter(z => z && (typeof z === 'object' ? z.item_id : z)).map(z => (typeof z === 'object' ? z.item_id : z))
  },
  mutateActiveItemId(state, item) {
    let foundIndex = state.activeItemIds.findIndex(b => b === item.item_id),
      index = foundIndex + 1 ? foundIndex : state.activeItemIds.length

    if (item.REMOVE) {
      state.activeItemIds.splice(index, 1)
    } else {
      state.activeItemIds[index] = item.item_id
    }
  },
  mutateBottles(state, payload) {
    state.bottles = payload
    state.bottlesById = arrayToObjectById(payload, 'item_id')
  },
  mutateBottle(state, item) {
    let foundIndex = state.bottles.findIndex(b => b.item_id === item.item_id),
      index = foundIndex + 1 ? foundIndex : state.bottles.length

    state.bottles[index] = item
    state.bottlesById[item.item_id] = item

    //TODO measure the performance impact of this
    state.bottlesById = { ...state.bottlesById }
    state.bottles = [...state.bottles]
    console.log('mutateBottle', 11111)
  },
  mutateBottlesAlgo(state, payload) {
    state.bottleAlgosById = arrayToObjectById(payload, 'item_id') || {}
  },
  mutateBottleAlgo(state, item) {
    if (item.REMOVE) {
      delete state.bottleAlgosById[item.item_id]
    } else {
      state.bottleAlgosById[item.item_id] = item
    }
    state.bottleAlgosById = { ...state.bottleAlgosById }
  },
  mutateItemPrices(state, prices) {
    let itemVariantPricesById = {}
    prices.forEach(p => {
      if (p.item_distributor_prices && p.item_distributor_prices.length) {
        p.item_distributor_prices.forEach(v => {
          itemVariantPricesById[v.item_distributor_id] = v
        })
      }
    })
    state.itemVariantPricesById = itemVariantPricesById
    state.itemPricesById = arrayToObjectById(prices, 'item_id')
  },
  mutateItemPrice(state, itemPrice) {
    if (itemPrice.REMOVE) {
      delete state.itemPricesById[itemPrice.item_id]
    } else {
      state.itemPricesById[itemPrice.item_id] = itemPrice
    }

    if (itemPrice.item_distributor_prices && itemPrice.item_distributor_prices.length) {
      itemPrice.item_distributor_prices.forEach(v => {
        state.itemVariantPricesById[v.item_distributor_id] = v
      })
      state.itemVariantPricesById = { ...state.itemVariantPricesById }
    }
    state.itemPricesById = { ...state.itemPricesById }
  },
  mutateItemParLevels(state, payload) {
    state.parLevelsById = arrayToObjectById(payload, 'item_id')
  },
  mutateItemParLevel(state, item) {
    if (item.REMOVE) {
      delete state.parLevelsById[item.item_id]
    } else {
      state.parLevelsById[item.item_id] = item
    }
    state.parLevelsById = { ...state.parLevelsById }
  },
  mutateItemVariants(state, payload) {
    state.itemVariants = payload
    state.itemVariantsById = arrayToObjectById(payload)
  },
  mutateItemVariant(state, item) {
    let foundIndex = state.itemVariants.findIndex(b => b.id === item.id),
      index = foundIndex + 1 ? foundIndex : state.itemVariants.length

    if (item.REMOVE) {
      state.itemVariants.splice(index, 1)
      delete state.itemVariantsById[item.id]
    } else {
      state.itemVariants[index] = item
      state.itemVariantsById[item.id] = item
    }
    state.itemVariantsById = { ...state.itemVariantsById }
  },
  mutateBottlesStockPrediction(state, payload) {
    console.log('mutateBottlesStockPrediction', payload.length)
    state.bottlesStockPredictionsById = arrayToObjectById(payload, 'item_id')
  },
  mutateBottleStockPrediction(state, item) {
    console.log('mutateBottleStockPrediction', item)
    if (item.REMOVE) {
      delete state.bottlesStockPredictionsById[item.item_id]
    } else {
      state.bottlesStockPredictionsById[item.item_id] = item
    }
    state.bottlesStockPredictionsById = { ...state.bottlesStockPredictionsById }
  },
  mutateBottlesStockPredictionFromSales(state, payload) {
    console.log('mutateBottlesStockPredictionFromSales', payload.length)
    state.bottleStockPredictionsFromSalesById = arrayToObjectById(payload, 'item_id')
  },
  mutateBottleStockPredictionFromSales(state, item) {
    console.log('mutateBottleStockPredictionFromSales', item)
    if (item.REMOVE) {
      delete state.bottleStockPredictionsFromSalesById[item.item_id]
    } else {
      state.bottleStockPredictionsFromSalesById[item.item_id] = item
    }
    state.bottleStockPredictionsFromSalesById = { ...state.bottleStockPredictionsFromSalesById }
  },
  mutateWiskModalTopLayer(state, payload) {
    state.wiskModalLayers = payload
  },
  mutateViewRefreshKey(state, payload) {
    state.viewRefreshKey = payload
  },
  mutateTranslations(state, payload) {
    state.translations = payload
  },
  mutateUIEditMode(state, payload) {
    state.uiEditModeActive = !!payload
  },
  mutateDarkModeTheme(state, payload) {
    state.darkModeTheme = payload
  },
  mutateGlobalAction(state, payload) {
    let preventDuplicate = {
      itemEdit: { idKey: 'item_id' },
      draftInvoiceEdit: { idKey: 'id' }
    }

    if (!Array.isArray(state.globalActions[payload.type])) {
      state.globalActions[payload.type] = []
    }

    if (payload.action && preventDuplicate[payload.type]) {
      let idKey = preventDuplicate[payload.type].idKey
      state.globalActions[payload.type] = state.globalActions[payload.type].filter(a => a[idKey] !== payload.action[idKey])
    }

    if (payload.preventMultiple) {
      if (payload.action) {
        state.globalActions[payload.type] = [payload.action]
      } else {
        state.globalActions[payload.type] = []
      }
    }

    if (!payload.preventMultiple) {
      if (payload.action) {
        state.globalActions[payload.type] = [...state.globalActions[payload.type], payload.action]
      } else {
        state.globalActions[payload.type].pop()
      }
    }

    state.globalActions = { ...state.globalActions }

    window.WiskGlobals = window.WiskGlobals || {}
    window.WiskGlobals.globalActions = cloneDeep(state.globalActions)
  },
  mutateQuotaExceededError(state, payload) {
    state.quotaExceededError = payload
  }
}
export default mutations
