<template>
  <wiskInput v-if="inline" infoTooltipKey="aa7391fd-0884-49af-a2a6-6959448ff3cc" fitChildren :label="translations.txtBarcodePrinterConfig" :modelValue="summary" showPlainText disabled
    :fitChildrenCols="3" class="light-control combined-control-input">
    <b-button type="button" variant="link" class="float-end h-100" size="sm" @click="setGlobalAction({ type: 'barcodePrinterSetup', action: {} })">
      <icon name="wisk-edit" scale=".7"></icon>&nbsp;
      {{ translations.txtGenericEdit }}
    </b-button>
  </wiskInput>

  <wiskModal v-else :visible="!!editAction" size="md" @ok="ok" :title="translations.txtBarcodePrinterConfig"
    :okDisabled="!valid" :okText="translations.txtGenericSave" @hide="setGlobalAction({ type: 'barcodePrinterSetup', action: null })">
    <wiskInputGroup @errorCountChanged="setValidState" style="min-height: 650px;">

      <infoTooltip inline class="" :scale="1.2" helpKey="barcodePrinterSetupInfoTooltip" />

      <wiskSelect infoTooltipKey="202cd169-8d25-4281-affd-d7756b83e8f0" :label="translations.txtBarcodePrinterName" v-model="selectedPrinterId"
        :items="printBarcodeConfigs" :multiselectOptions="{}" class="mb-4" />

      <wiskSelect v-if="selectedPrinterId && labels && labels.length" infoTooltipKey="578677f4-cf29-4473-9652-1a4fa2e34919" :label="translations.txtBarcodePrinterLabelSize" v-model="selectedLabel"
        :items="labels" required class="mb-4" />

      <div>
        <icon name="wisk-information-button" scale=".8" class="text-primary"></icon>
        {{ translations.txtBarcodePrinterSoftware }}
      </div>
    </wiskInputGroup>

  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'BarcodePrinterSetup',
  components: {},
  props: { inline: Boolean, editAction: { type: Object } },
  data() {
    return {
      valid: false,
      selectedPrinterId: null,
      selectedLabel: null
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters(['printBarcodeConfigs', 'venue']),
    summary() {
      if (this.selectedPrinter && this.selectedLabel) {
        return this.selectedPrinter.title + ': ' + this.selectedLabel
      }
      return this.translations.txtBarcodePrinterNone
    },
    selectedPrinter() {
      if (this.selectedPrinterId && this.printBarcodeConfigs) {
        let found = this.printBarcodeConfigs.find(p => p.id === this.selectedPrinterId)

        if (found) {
          let label = found.label

          if (this.venue.print_barcode_configs && this.venue.print_barcode_configs[0] && this.venue.print_barcode_configs[0].label) {
            label = this.venue.print_barcode_configs[0].label
          }
          return { ...found, label }
        }
      }
      return null
    },
    labels() {
      return this.selectedPrinter.labels || []
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'updateVenue']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    ok() {
      let saveNewConfig = () => {
        if (this.selectedPrinter) {
          this.updateVenue({
            id: this.venue.id,
            operation: { type: 'print_barcode_config_add', value: { ...this.selectedPrinter, label: this.selectedLabel, software: 'connect' } }
          }).then(() => {
            this.setGlobalAction({ type: 'barcodePrinterSetup', action: null })
          })
        }
      }

      if (this.venue.print_barcode_configs && this.venue.print_barcode_configs.length) {
        let operations = []
        //for now we keep only one configuration per venue
        this.venue.print_barcode_configs.forEach(value => {
          operations.push({ type: 'print_barcode_config_delete', value })
        })

        this.updateVenue({ id: this.venue.id, operation: operations }).then(saveNewConfig)
      } else {
        saveNewConfig()
      }
    }
  },
  watch: {
    venue: {
      immediate: true,
      handler() {
        if (this.venue && this.venue.print_barcode_configs && this.venue.print_barcode_configs.length && this.venue.print_barcode_configs[0]) {
          this.selectedPrinterId = this.venue.print_barcode_configs[0].id
          this.selectedLabel = this.venue.print_barcode_configs[0].label
        }
      }
    }
  }
}
</script>

<style lang="scss">

</style>
