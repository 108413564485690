import { prepareItemsForWiskInput } from '@/modules/utils'

export default translations => ({
  aldelo: {
    data: {
      store_app_token: '',
      store_subscription_id: ''
    },
    fields: {
      store_app_token: {
        title: translations.txtPOSExtraAldeloAppStoreToken
      },
      store_subscription_id: {
        title: translations.txtPOSExtraAldeloAppStoreSubscription
      }
    }
  },
  arryved: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  arryvedapi: {
    data: {
      company_id: '',
      network_id: '',
      auth_token: '',
      import_all_pos_items: false,
      include_modifiers: false,
      include_modifiers_without_price: false,
      modifiers_as_separate_items: false
    },
    fields: {
      company_id: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraCompanyId
      },
      network_id: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraNetworkId
      },
      auth_token: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraAuthToken
      },
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      },
      include_modifiers: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeModifiers,
        type: 'checkbox'
      },
      include_modifiers_without_price: {
        availableForNormalUsers: true,
        optional: true,
        conditional_render: 'include_modifiers',
        title: translations.txtPOSExtraIncludeModifiersWithPriceOfZero,
        type: 'checkbox'
      },
      modifiers_as_separate_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraModifiersAsSeparateItems,
        conditional_render: 'include_modifiers',
        type: 'checkbox'
      }
    }
  },
  fudo: {
    data: {
      api_key: '',
      api_secret: '',
      import_all_pos_items: false
    },
    fields: {
      api_key: {
        availableForNormalUsers: true,
        title: translations.txtGenericApiKey
      },
      api_secret: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraApiSecret
      },
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      }
    }
  },
  avero: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  breadcrumb: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  clover: {
    data: {
      import_all_pos_items: false,
      include_modifiers: false,
      modifiers_as_separate_items: false,
      selected_modifiers: [],

      merchant_id: '',
      api_key: ''
    },
    fields: {
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      },
      include_modifiers: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeModifiers,
        type: 'checkbox'
      },
      modifiers_as_separate_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraModifiersAsSeparateItems,
        conditional_render: 'include_modifiers',
        type: 'checkbox'
      },
      selected_modifiers: {
        availableForNormalUsers: true,
        optional: true,
        conditional_render: 'modifiers_as_separate_items', //value must be truthy ({}, [], 1, true, "abc")
        title: 'select modifiers',
        itemsKey: 'cloverModifiers',
        parentGridName: 'cloverModifiersGrid',
        type: 'gridMultiSelect'
      },

      merchant_id: {
        title: 'merchant_id',
        availableForNormalUsers: false,
        optional: false
      },
      api_key: {
        title: 'api_key',
        availableForNormalUsers: false,
        optional: false
      }
    }
  },
  clover_oauth2: {
    data: {
      import_all_pos_items: false,
      include_modifiers: false,
      modifiers_as_separate_items: false,
      selected_modifiers: [],

      merchant_id: ''
    },
    fields: {
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      },
      include_modifiers: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeModifiers,
        type: 'checkbox'
      },
      modifiers_as_separate_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraModifiersAsSeparateItems,
        conditional_render: 'include_modifiers',
        type: 'checkbox'
      },
      selected_modifiers: {
        availableForNormalUsers: true,
        optional: true,
        conditional_render: 'modifiers_as_separate_items', //value must be truthy ({}, [], 1, true, "abc")
        title: 'select modifiers',
        itemsKey: 'cloverModifiers',
        parentGridName: 'cloverModifiersGrid',
        type: 'gridMultiSelect'
      },

      merchant_id: {
        title: 'Merchant ID',
        availableForNormalUsers: true,
        disabled: true,
        optional: true
      }
    }
  },
  cluster: {
    data: {
      credentials: [{ api_key: '', serial: '' }]
    },
    fields: {
      credentials: {
        availableForNormalUsers: true,
        allowAddRemove: true,
        title: translations.txtGenericCredentials,
        type: 'array',
        seed: { api_key: '', serial: '' },
        fields: {
          api_key: {
            title: translations.txtPOSExtraAPIKey
          },
          serial: {
            title: translations.txtPOSExtraSerial
          }
        }
      }
    }
  },
  delarosa: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  digitaldining: {
    data: {
      bucket: '',
      folder: ''
    },
    fields: {
      bucket: {
        title: translations.txtPOSExtraBucket
      },
      folder: {
        title: translations.txtPOSExtraFolder
      }
    }
  },
  dino: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  easygoband: {
    data: {
      api_key: '',
      import_all_pos_items: false
    },
    fields: {
      api_key: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraAPIKey
      },
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      }
    }
  },
  fastbar: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  focus: {
    data: {
      label: '',
      net_column: '',
      plu_column: '',
      qty_column: '',
      title_column: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      },
      net_column: {
        title: translations.txtPOSExtraNetColumn
      },
      plu_column: {
        title: translations.txtPOSExtraPluColumn
      },
      qty_column: {
        title: translations.txtPOSExtraQtyColumn
      },
      title_column: {
        title: translations.txtPOSExtraTitleColumn
      }
    }
  },
  futurepos: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  harbortouch: {
    data: {
      label: '',
      net_column: '',
      plu_column: '',
      qty_column: '',
      title_column: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      },
      net_column: {
        title: translations.txtPOSExtraNetColumn
      },
      plu_column: {
        title: translations.txtPOSExtraPluColumn
      },
      qty_column: {
        title: translations.txtPOSExtraQtyColumn
      },
      title_column: {
        title: translations.txtPOSExtraTitleColumn
      }
    }
  },
  heartland: {
    data: {
      location_key: '',
      import_all_pos_items: false,
      include_modifiers: false,
      modifiers_as_separate_items: false
    },
    fields: {
      location_key: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraHeartlandLocationKey
      },
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      },
      include_modifiers: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeModifiers,
        type: 'checkbox'
      },
      modifiers_as_separate_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraModifiersAsSeparateItems,
        type: 'checkbox'
      }
    }
  },
  kounta: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  lavu: {
    data: {
      dataname: '',
      key: '',
      token: ''
    },
    fields: {
      dataname: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraDataName
      },
      key: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraKey
      },
      token: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraToken
      }
    }
  },
  lightspeed: {
    data: {
      company_id: 0,
      import_all_pos_items: false,
      include_modifiers_without_price: false,
      server: ''
    },
    fields: {
      company_id: {
        title: translations.txtPOSExtraCompanyId,
        type: 'number'
      },
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      },
      include_modifiers_without_price: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeModifiersWithPriceOfZero,
        type: 'checkbox'
      },
      server: {
        items: prepareItemsForWiskInput(['nae1', 'euw1', 'euw2', 'nae2', 'euw3', 'naw1', 'aps1', 'staging', 'apc1']),
        title: translations.txtPOSExtraServer,
        type: 'select'
      }
    }
  },
  lightspeed_oauth2: {
    data: {
      import_all_pos_items: false,
      include_modifiers_without_price: false,
      modifiers_as_separate_items: false
    },
    fields: {
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      },
      include_modifiers_without_price: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeModifiersWithPriceOfZero,
        type: 'checkbox'
      },
      modifiers_as_separate_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraModifiersAsSeparateItems,
        type: 'checkbox'
      }
    }
  },
  lightspeed_kseries: {
    data: {
      import_all_pos_items: false,
      include_modifiers_without_price: false,
      modifiers_as_separate_items: false
    },
    fields: {
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      },
      include_modifiers_without_price: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeModifiersWithPriceOfZero,
        type: 'checkbox'
      },
      modifiers_as_separate_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraModifiersAsSeparateItems,
        type: 'checkbox'
      }
    }
  },
  loyverse: {
    data: {
      token: ''
    },
    fields: {
      token: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraAPIToken
      }
    }
  },
  maitred: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  maitredxml: {
    data: {
      bucket: '',
      folder: ''
    },
    fields: {
      bucket: {
        title: translations.txtPOSExtraBucket
      },
      folder: {
        title: translations.txtPOSExtraFolder
      }
    }
  },
  micros: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  mobilebytes: {
    data: {
      location_key: '',
      import_all_pos_items: false,
      include_modifiers: false,
      modifiers_as_separate_items: false
    },
    fields: {
      location_key: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraHeartlandLocationKey
      },
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      },
      include_modifiers: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeModifiers,
        type: 'checkbox'
      },
      modifiers_as_separate_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraModifiersAsSeparateItems,
        type: 'checkbox'
      }
    }
  },
  mobipos: {
    data: {
      api_token: '',
      outlet: ''
    },
    fields: {
      api_token: {
        title: translations.txtPOSExtraAPIToken
      },
      outlet: {
        title: translations.txtPOSExtraOutlet
      }
    }
  },
  omnivore: {
    data: {
      import_all_pos_items: false,
      include_modifiers: false,
      modifiers_as_separate_items: false,
      include_modifiers_without_price: false,
      include_submodifiers: false,
      location_id: ''
    },
    fields: {
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      },
      include_modifiers: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeModifiers,
        type: 'checkbox'
      },
      location_id: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraLocationId
      },
      modifiers_as_separate_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraModifiersAsSeparateItems,
        type: 'checkbox'
      },
      include_modifiers_without_price: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeModifiersWithPriceOfZero,
        type: 'checkbox'
      },
      include_submodifiers: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeSubmodifiers,
        type: 'checkbox'
      },
      use_pos_code: {
        availableForNormalUsers: false,
        optional: true,
        title: translations.txtPOSExtraUsePOSCode,
        type: 'checkbox'
      },
      include_revenue_centers: {
        availableForNormalUsers: false,
        optional: true,
        title: translations.txtPOSExtraImportSalesByRevenueCenter,
        type: 'checkbox'
      }
    }
  },
  onepos: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  restaurantmanager: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  revel: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  rpower: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  silverware: {
    data: {
      authorization: '',
      import_all_pos_items: false,
      server: '',
      use_revenue_centers: false,
      legacy_plu_number: false
    },
    fields: {
      authorization: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraAuthorization
      },
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      },
      server: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraServer
      },
      use_revenue_centers: {
        availableForNormalUsers: false,
        optional: true,
        title: translations.txtPOSExtraImportSalesByRevenueCenter,
        type: 'checkbox'
      },
      legacy_plu_number: {
        availableForNormalUsers: false,
        optional: true,
        title: translations.txtPOSExtraImportSalesByLegacyPluNumber,
        type: 'checkbox'
      }
    }
  },
  smarttab: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  square: {
    data: {
      import_all_pos_items: false,
      locations: [],
      modifiers_as_separate_items: false,
      use_revenue_centers: false,
      fetch_pos_items: true
    },
    fields: {
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      },
      locations: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtGenericLocations,
        type: 'gridMultiSelect',
        parentGridName: 'posExtraFieldsLocationsGrid'
      },
      modifiers_as_separate_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraModifiersAsSeparateItems,
        type: 'checkbox'
      },
      fetch_pos_items: {
        availableForNormalUsers: false,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItems,
        type: 'checkbox'
      }
    }
  },
  squirrel: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  toast: {
    data: {
      access_key: '',
      restaurant_id: '',
      secret: '',
      username: ''
    },
    fields: {
      access_key: {
        title: translations.txtPOSExtraAccessKey
      },
      restaurant_id: {
        title: 'GUID',
        availableForNormalUsers: false
      },
      secret: {
        title: translations.txtPOSExtraSecret
      },
      username: {
        title: translations.txtPOSExtraUsername
      }
    }
  },
  tevalis: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  toastapi: {
    data: {
      import_all_pos_items: false,
      include_modifiers: false,
      include_modifiers_without_price: false,
      modifiers_as_separate_items: false,
      include_group_id_into_plu_number: false,
      import_special_requests: false,
      restaurant_id: ''
    },
    fields: {
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      },
      include_modifiers: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeModifiers,
        type: 'checkbox'
      },
      include_modifiers_without_price: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeModifiersWithPriceOfZero,
        type: 'checkbox'
      },
      modifiers_as_separate_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraModifiersAsSeparateItems,
        type: 'checkbox'
      },
      include_group_id_into_plu_number: {
        availableForNormalUsers: false,
        optional: true,
        title: translations.txtPOSExtraIncludeGroupIdIntoPluNumber,
        type: 'checkbox'
      },
      import_special_requests: {
        availableForNormalUsers: false,
        optional: true,
        title: translations.txtPOSExtraImportSpecialRequests,
        type: 'checkbox'
      },
      restaurant_id: {
        availableForNormalUsers: true,
        title: 'GUID'
      }
    }
  },
  touchbistroapi: {
    data: {
      import_all_pos_items: false,
      include_modifiers: true,
      modifiers_as_separate_items: true,
      include_modifiers_without_price: false,
      include_text_modifiers: false,
      restaurant_id: 0
    },
    fields: {
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      },
      include_modifiers: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeModifiers,
        type: 'checkbox'
      },
      modifiers_as_separate_items: {
        availableForNormalUsers: true,
        optional: true,
        conditional_render: 'include_modifiers',
        title: translations.txtPOSExtraModifiersAsSeparateItems,
        type: 'checkbox'
      },
      include_text_modifiers: {
        availableForNormalUsers: true,
        optional: true,
        conditional_render: 'include_modifiers',
        title: translations.txtPOSExtraIncludeTextModifiers,
        type: 'checkbox'
      },
      include_modifiers_without_price: {
        availableForNormalUsers: true,
        optional: true,
        conditional_render: 'include_modifiers',
        title: translations.txtPOSExtraIncludeModifiersWithPriceOfZero,
        type: 'checkbox'
      },
      restaurant_id: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraRestaurantId,
        type: 'number'
      }
    }
  },
  treez: {
    data: {
      api_key: '',
      dispensary_name: '',
      import_all_pos_items: false
    },
    fields: {
      api_key: {
        title: translations.txtPOSExtraAPIKey,
        availableForNormalUsers: true
      },
      dispensary_name: {
        title: translations.txtPOSExtraDispensaryName,
        availableForNormalUsers: true
      },
      include_modifiers: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeModifiers,
        type: 'checkbox'
      }
    }
  },
  union: {
    data: {
      price_as_modifier: false
    },
    fields: {
      price_as_modifier: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraPriceAsModifier,
        type: 'checkbox'
      }
    }
  },
  upserve: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  veloce: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  veloceapi: {
    data: {
      import_all_pos_items: false,
      location_id: '',
      big_division_ids: [],
      modifiers_as_separate_items: true
    },
    fields: {
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      },
      location_id: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraLocationId
      },
      big_division_ids: {
        availableForNormalUsers: true,
        optional: true,
        conditional_render: 'location_id', //value must be truthy ({}, [], 1, true, "abc")
        title: 'Select divisions',
        itemsKey: 'veloceapiDivisions',
        parentGridName: 'veloceapiDivisionsGrid',
        type: 'gridMultiSelect'
      },
      modifiers_as_separate_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraModifiersAsSeparateItems,
        type: 'checkbox'
      }
    }
  },
  vendus: {
    api_key: {
      label: ''
    },
    fields: {
      api_key: {
        title: translations.txtPOSExtraAPIKey
      }
    }
  },
  visualtouch: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  volante: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  agilysys: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  xetux: {
    data: {
      host: '',
      import_all_pos_items: false
    },
    fields: {
      host: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraHostAddress
      },
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      }
    }
  },
  myr: {
    data: {
      api_key: ''
    },
    fields: {
      api_key: {
        availableForNormalUsers: true,
        title: translations.txtGenericApiKey
      }
    }
  },
  myrapi: {
    data: {
      include_modifiers: false,
      include_modifiers_without_price: false,
      modifiers_as_separate_items: false,
      location_id: ''
    },
    fields: {
      include_modifiers: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeModifiers,
        type: 'checkbox'
      },
      include_modifiers_without_price: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeModifiersWithPriceOfZero,
        type: 'checkbox'
      },
      modifiers_as_separate_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraModifiersAsSeparateItems,
        type: 'checkbox'
      },
      location_id: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraLocationId
      }
    }
  },
  abm: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  },
  gofun: {
    data: {
      api_key: '',
      import_all_pos_items: false,
      go_fun_venue_id: ''
    },
    fields: {
      api_key: {
        availableForNormalUsers: true,
        title: translations.txtGenericApiKey
      },
      go_fun_venue_id: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraGoFunVenueId
      },
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      }
    }
  },
  gotab: {
    data: {
      location_id: '',
      import_all_pos_items: false
    },
    fields: {
      location_id: {
        availableForNormalUsers: true,
        title: translations.txtPOSExtraLocationId
      },
      import_all_pos_items: {
        availableForNormalUsers: true,
        optional: true,
        title: translations.txtPOSExtraIncludeAllPOSItemsEvenIfNotSold,
        type: 'checkbox'
      }
    }
  },
  jonas: {
    data: {
      label: ''
    },
    fields: {
      label: {
        title: translations.txtGenericLabel
      }
    }
  }
})
