<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'locationEdit', action: null })" :title="modalTitle" size="md" :hideFooter="!!(location && location.id)"
    :warning="!!(location && location.archived)">
    <template v-slot:modal-header-extra>
      <icon class="text-success float-start ms-4" name="wisk-check" scale="1.2" v-if="location && location.is_default"
        v-tooltip="translations.txtGenericDefault" />

      <wiskActions :title="translations.txtGenericActions" v-if="location && location.id" :actions="pageActions" />
    </template>

    <wiskInputGroup v-if="location" @errorCountChanged="setValidState" style="min-height: 500px;" @operationsChange="onChange">
      <b-row>

        <b-col :md="4">
          <div class="position-relative images-controls-wrapper mb-3">
            <imageView :src="localLocation.image" @click="onImageClick" hoverIcon="wisk-edit" filterDisplay="smallLandscape" filterHover="mdLargeLandscape" class="d-flex justify-content-center" imgClass="w-100" />
          </div>
        </b-col>

        <b-col>
          <wiskInput infoTooltipKey="75cf39d9-9cc6-40f4-8088-3845a2526e36" :label="translations.txtGenericTitle" operation="title" :modelValue="localLocation.title" @operation="onSingleChange"
            required autofocus />

          <wiskSelect infoTooltipKey="bc92e379-95e0-420f-a8e6-265584e2168b" :label="translations.txtSalesGridRevenueCenters" v-if="posIntegrationSettings.support_revenue_centers" :items="availableRevenueCenters" multiple
            :modelValue="localLocation.revenue_center_ids" @operation="onSingleChange" operation="revenue_center_id_add" operationEmpty="revenue_center_id_delete" />

          <b-button v-if="location.id && !location.is_default" class="mt-4" variant="outline-primary" v-tooltip="translations.txtCustomFieldEdit"
            @click="save({ type: 'set_default' })">
            {{ translations.txtGenericSetAsDefault }}
          </b-button>
        </b-col>

      </b-row>
    </wiskInputGroup>
    <confirm ref="confirmDialog" />

    <wiskLoading :loading="loading" />

    <template v-slot:modal-footer>
      <editorButtons :editorValid="valid" :save="save" />
    </template>
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import merge from 'lodash.merge'
import api from '@/api'
import editorButtons from '@/components/common/WiskEditorModalButtons'
import imageView from '@/components/common/ImageView'

export default {
  name: 'LocationEdit',
  components: { editorButtons, imageView },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      valid: true,
      loading: false,
      localLocation: {},
      operations: [],
    }
  },
  computed: {
    ...mapState(['translations', 'currentPermissionsByType', 'locationsById', 'revenueCenters', 'locations']),
    ...mapGetters(['venue', 'posIntegrationSettings']),
    pageActions() {
      if (this.location) {
        let actions = [
          { key: 1, title: this.translations.txtGenericArchive, icon: 'wisk-archive', variant: 'danger', hide: this.location.archived, action: this.toggleArchived },
          { key: 2, title: this.translations.txtGenericRestore, icon: 'wisk-history', variant: 'success', hide: !this.location.archived, action: this.toggleArchived }
        ]
        if (this.currentPermissionsByType.item_view) {
          actions.unshift(
            {
              key: 10,
              title: this.translations.txtVenueBottlesPDF + ' - ' + this.translations.txtVenueBottlesPDFSortedByTitle,
              icon: 'wisk-pdf',
              action: () => this.emailItemsPDF('title')
            },
            {
              key: 11,
              title: this.translations.txtVenueBottlesPDF + ' - ' + this.translations.txtVenueBottlesPDFSortedByPreviousInventory,
              icon: 'wisk-pdf',
              action: () => this.emailItemsPDF('inventory_order')
            }
          )
        }

        return actions
      }
      return []
    },
    modalTitle() {
      if (this.location) {
        return this.location.title || this.translations.txtLocationsNew
      }
      return ''
    },
    location() {
      if (this.editAction) {
        if (this.editAction.id) {
          return this.locationsById[this.editAction.id]
        }

        return merge({}, this.editAction)
      }

      return null
    },
    availableRevenueCenters() {
      let linkedRevenueCenters = this.locations.filter(loc => loc.id !== this.location.id).map(loc => loc.revenue_center_id)
      return this.revenueCenters.filter(revenueCenter => !linkedRevenueCenters.includes(revenueCenter.id))
    },
    availableRevenueCenters2() {
      let linkedRevenueCenters = this.locations
        .filter(loc => loc.id !== this.location.id)
        .flatMap(loc => loc.revenue_center_ids)

      return this.revenueCenters.filter(revenueCenter => !linkedRevenueCenters.includes(revenueCenter.id))
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'setLocation']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    emailItemsPDF(sort = 'title') {
      if (this.location) {
        api.emailPdf('items/by-location/pdf',
          // 'Items by location.pdf',
          {
            location_ids: [this.location.id],
            sort_by: sort
          })
      }
    },
    onChange(operations) {
      this.operations = [...operations]
    },
    onSingleChange(operation) {
      if (this.location && this.location.id) {
        this.save(operation)
      }
    },
    onImageClick() {
      this.setGlobalAction({
        type: 'fileUpload',
        action: {
          target: this.location,
          fileTypesAccept: 'image/*',
          useGoogle: true,
          save: value => {
            if (this.location.id) {
              this.save({ type: 'image', value })
            } else {
              this.localLocation.image = value
              this.operations = this.operations.filter(o => o.type !== 'image')
              this.operations.push({ type: 'image', value })
            }
          }
        }
      })
    },
    save(operation) {
      if (this.valid || operation) {
        this.loading = true

        this.setLocation({ id: this.location.id, operation: operation || this.operations })
          .then(() => {
            this.operations = []
            this.loading = false

            if (!operation) {
              this.setGlobalAction({ type: 'locationEdit', action: null })
            }
          })
          .catch(() => {
            this.loading = false
            this.operations = []
          })
      }
    },
    toggleArchived() {
      if (this.$refs.confirmDialog && this.location) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.save({ type: 'archive', value: !this.location.archived })
          },
          message: this.location.archived ? this.translations.confirmRestoreText : this.translations.confirmArchiveText,
          title: this.location.archived ? this.translations.confirmRestoreTitle : this.translations.confirmArchiveTitle
        })
      }
    }
  },
  watch: {
    location: {
      handler() {
        this.localLocation = merge({}, this.location)
        if (this.localLocation.revenue_center_id && !this.localLocation.revenue_center_ids) {
          this.localLocation.revenue_center_ids = [this.localLocation.revenue_center_id]
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss"></style>
