<template>
  <div class="venues-users">
    <wiskGrid :rowData="usersVenuesData" :gridStyle="gridStyle" :fitInAppBody="!gridStyle" :loadingOverlay="loading" :key="key" @gridApi="gridReady"
      v-if="user && gridColumns && gridColumns.length" :columnDefs="gridColumns" :gridOptions="options" :header="gridHeaderConfig" :gridAutoHeight="gridAutoHeight"
      :parentGridName="parentGridName" @selectedRowsChanged="selectedRowsChanged" groupCheckboxSelection checkBoxSelection :selectedRowsActions="selectedRowsActions" />

    <wiskModal size="md" :title="translations.txtUsersEmailReports" hideFooter v-model="emailSettingsModalOpen">
      <wiskInputGroup>
        <wiskInput :infoTooltipKey="`email_reports_${config.id}_${config.channel}`" v-for="config in emailSettingsForSelectedUserVenueComputed" :key="config.id + config.channel"
          :label="config.title" :modelValue="config.enabled" inputType="checkbox" inputClass="" class="m-0 p-0 " @operation="saveEmailConfig($event, config)"
          operation="add_notification_type" operationEmpty="remove_notification_type" />
      </wiskInputGroup>
    </wiskModal>

    <confirm ref="confirmDialog" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import get from 'lodash.get'
import { toClipboard } from '@soerenmartius/vue3-clipboard'
import api from '@/api'
import { sortObject, arrayToObjectById } from '@/modules/utils'
import wiskGrid from '@/components/grids/WiskGrid'
import cellRenderers from '@/components/cells/cellRenderers'

export default {
  name: 'VenuesUsers',
  emits: ['refresh'],
  components: { wiskGrid },
  props: {
    hideHeader: { type: Boolean, default: false },
    gridAutoHeight: { type: Boolean, default: false },
    parentGridName: { type: String, default: 'VenuesUsers' },
    hideColumns: { type: Array, default: () => [] },
    usersVenuesData: { type: Array, default: () => [] },
    gridStyle: { type: Object }
  },
  data() {
    return {
      gridApi: null,
      loading: false,
      emailSettingsModalOpen: false,
      selectedUserVenueId: null,
      selectedRows: [],
      gridColumns: [],
      key: 1
    }
  },
  computed: {
    ...mapState(['user', 'translations', 'roles', 'rolesByVenueId', 'currentPermissionsByType', 'permissionsById', 'venuesHighLevelById']),
    ...mapGetters(['permissionsByVenueIdByType', 'userNotificationTypes']),
    selectedRowsActions() {
      let actions = [],
        canRemoveUsers = this.canRemoveUsers,
        canChangeRole = this.canChangeRole

      if (canRemoveUsers) {
        actions.push({
          key: 'removeSelectedUsers',
          type: 'button',
          variant: 'danger',
          action: rows => {
            this.removeSelectedUsers(rows)
          },
          title: this.translations.txtGenericRemove
        })
      }

      if (canChangeRole) {
        actions.push({
          key: 'role_id',
          type: 'genericInput',
          inputTypeAttrs: {
            operation: 'role_id',
            items: this.rolesByVenueId[this.selectedVenueId],
            inputType: 'wiskSelect',
            clearAfterEmitOperation: true,
            label: this.translations.txtGenericRole
          },
          operation: (operation, rows) => {
            this.updateSelectedUserVenues(operation, rows)
          }
        })
      }

      return actions
    },
    emailSettingsForSelectedUserVenueComputed() {
      return this.userNotificationTypes.map(n => {
        let found = this.emailSettingsForSelectedUserVenue.find(z => z.type === n.id)
        return { ...n, enabled: !!found, channel: 'email' } //TODO: review what is this channel for
      })
    },
    userNotificationTypesById() {
      return arrayToObjectById(this.userNotificationTypes)
    },
    emailSettingsForSelectedUserVenue() {
      if (this.selectedUserVenueId && this.usersVenuesData && this.usersVenuesData.length) {
        let found = this.usersVenuesData.find(uv => uv.id === this.selectedUserVenueId)
        if (found) {
          return found.notifications_config
        }
        return []
      }

      return []
    },
    gridHeaderConfig() {
      return { hideSearch: this.user.god_mode, hideHeader: this.hideHeader }
    },
    selectedVenueId() {
      let venueId = 0,
        singleVenue = true

      this.selectedRows.forEach(row => {
        if (!venueId) {
          venueId = row.venue.id
        }
        if (venueId !== row.venue.id) {
          singleVenue = false
        }
      })
      if (!singleVenue) {
        return null
      }
      return venueId
    },

    canChangeRole() {
      let permissions = (this.selectedVenueId && this.getPermissionsByVenueIdByType(this.selectedVenueId)) || null
      if (permissions) {
        return !!permissions.user_role_manage
      }

      return false
    },
    canRemoveUsers() {
      let permissions = (this.selectedVenueId && this.getPermissionsByVenueIdByType(this.selectedVenueId)) || null
      if (permissions) {
        return !!permissions.user_remove
      }

      return false
    },
    options() {
      return {
        getRowHeight: params => (params.data && (!params.data.id || params.data.wiskRowHidden) && 1) || 45,
        headerHeight: 30,
        rowSelection: {
          isRowSelectable: node => {
            if (node?.data?.user && node.data.user.id !== this.user.id) {
              let venueId = node.data.venue.id,
                permissions = this.getPermissionsByVenueIdByType(venueId)

              return !!(permissions.user_remove || permissions.user_role_manage) && node.data && !node.data.wiskRowHidden
            }
            return false
          }
        },
        autoGroupColumnDef: {
          suppressColumnsToolPanel: true,
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          cellClass: params => (params.node.group && ['wisk-full-width-cell', 'wisk-group-actions-wrapper', 'header']) || [''],
          cellRendererParams: {
            wiskGroupActions: [
              {
                action: this.addUser,
                title: this.translations.txtAuthUserAdd,
                variant: 'primary',
                available: venueId => {
                  if (venueId) {
                    let permissions = this.getPermissionsByVenueIdByType(venueId) || {}

                    return !!permissions.user_add
                  }

                  return false
                }
              },
              {
                action: this.editVenue,
                title: this.translations.txtGenericEdit,
                available: venueId => {
                  if (venueId) {
                    let permissions = this.getPermissionsByVenueIdByType(venueId) || {}

                    return !!permissions.venue_settings_manage
                  }

                  return false
                }
              },
              {
                action: id => {
                  window.open(`${location.origin}/${id}`, '_blank')
                },
                title: this.translations.txtGenericOpenVenueNewTab,
                variant: 'primary'
              }
            ]
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    gridReady(z) {
      this.gridApi = z
    },
    getPermissionsByVenueIdByType(venueId) {
      return get(this.user, 'god_mode') ? this.currentPermissionsByType || {} : (this.permissionsByVenueIdByType || {})[venueId] || {}
    },
    selectedRowsChanged(rows) {
      this.selectedRows = [...rows]
    },
    deselectAll() {
      this.gridApi && this.gridApi.deselectAll()
    },
    addUser(venueId) {
      this.setGlobalAction({ type: 'inviteUsers', action: { onChange: this.refresh, venueId } })
    },
    editVenue(id) {
      this.setGlobalAction({ type: 'venueEdit', action: { onChange: this.refresh, id } })
    },
    refresh() {
      this.$emit('refresh')
    },
    removeSelectedUsers(rows) {
      if (this.selectedVenueId && rows && rows.length) {
        let selectedUserIds = rows.map(r => r.user.id),
          all = []

        if (this.$refs.confirmDialog) {
          this.$refs.confirmDialog.confirm({
            callback: () => {
              this.loading = true
              selectedUserIds.forEach(id => {
                all.push(api.updateUser(id, { value: this.selectedVenueId, type: 'remove_from_venue_id' }))
              })

              Promise.all(all).finally(() => {
                this.deselectAll()
                this.refresh()
                this.loading = false
              })
            },
            message: this.translations.confirmUserDeleteFromVenueText,
            title: this.translations.confirmUserDeleteFromVenueTitle
          })
        }
      }
    },
    updateSelectedUserVenues(operation, rows) {
      if (operation && this.selectedVenueId && rows && rows.length) {
        let selectedUserVenueIds = rows.map(r => r.id),
          all = []

        selectedUserVenueIds.forEach(id => {
          all.push(api.updateUserVenue(id, operation))
        })

        Promise.all(all).finally(() => {
          this.deselectAll()
          this.refresh()
        })
      }
    },
    updateUser(value, id, type) {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            api.updateUser(id, { value, type }).then(this.refresh)
          },
          message: this.translations.confirmUserDeleteFromVenueText,
          title: this.translations.confirmUserDeleteFromVenueTitle
        })
      }
    },
    saveEmailConfig(operation = {}, value = {}) {
      if (this.selectedUserVenueId) {
        this.save({ type: value.id, channel: value.channel }, this.selectedUserVenueId, operation.type)
      }
    },
    save(value, id, type) {
      return new Promise(resolve => {
        api.updateUserVenue(id, { value, type }).then(() => {
          this.refresh()
          resolve()
        })
      })
    },
    createColumnDefs() {
      if (this.user) {
        this.gridColumns = [
          {
            colId: 'dropdownMenu',
            remove: this.hideColumns.includes('dropdownMenu'),
            cellRenderer: 'CellMenu',
            headerName: '',
            headerClass: ['ps-2', 'pt-1'],
            sortOrder: 0,
            maxWidth: 25,
            width: 25,
            minWidth: 25,
            cellClass: ['menu-cell'],
            sortable: false,
            suppressColumnsToolPanel: true,
            suppressSortingToolPanel: true,
            suppressFiltersToolPanel: true,
            cellRendererParams: { excludeFromExport: true },
            valueGetter: params => {
              let permissions = this.getPermissionsByVenueIdByType(get(params, 'data.venue.id')) || {}

              return {
                group: params.node.group,
                items: [
                  {
                    onClick: data => {
                      this.updateUser(data.venue.id, data.user.id, 'remove_from_venue_id')
                    },
                    hidden: get(params, 'data.user.id') === this.user.id || !permissions.user_remove,
                    label: this.translations.txtGenericRemove,
                    value: params.data
                  }
                ]
              }
            }
          },
          {
            headerName: this.translations.txtGenericVenue,
            colId: 'venueTitle',
            remove: this.hideColumns.includes('venueTitle'),
            sortOrder: 100,
            hide: true,
            suppressColumnsToolPanel: true,
            suppressSortingToolPanel: true,
            suppressFiltersToolPanel: true,
            minWidth: 120,
            keyCreator: params => JSON.stringify(sortObject(params.value)),
            valueGetter: params => {
              if (params.data && params.data.venue) {
                return { colId: 'venueTitle', id: params.data.venue.id, title: params.data.venue.title, sort: params.data.venue.title }
              }
              return { title: 'id missing!!!' }
            }
          },
          {
            headerName: this.translations.txtGenericVenue,
            colId: 'venue',
            remove: this.hideColumns.includes('venue'),
            sortOrder: 200,
            hide: true,
            minWidth: 120,
            valueGetter: params => (params.data?.venue?.title && (params.data.venue.title + ` (${params.data.venue.id})`)) || '',
          },
          { colId: 'userFullName', sortOrder: 300, field: 'user_full_name', headerName: this.translations.txtGenericUser, minWidth: 120 },
          {
            colId: 'email',
            sortOrder: 400,
            headerName: this.translations.txtGenericEmail,
            minWidth: 120,
            cellRenderer: 'CellText',
            keyCreator: params => params?.data?.input_value?.toString(),
            cellRendererParams: {
              translate: this.translations.translate,
              readonly: true,
              extraButtons: [
                {
                  id: 1,
                  getVisible: () => this.user.god_mode,
                  action: (id, data) => {
                    toClipboard(data?.input_value)
                  },
                  icon: 'wisk-copy',
                  title: this.translations.txtGenericCopyClipboard
                }
              ],
              infoComponentType: 'extraButtons'
            },
            valueGetter: params => ({
              id: params?.data?.id,
              input_value: params?.data?.user_email || ''
            })
          },
          {
            headerName: this.translations.txtUsersEmailReports,
            colId: 'userNotificationTypes',
            remove: this.hideColumns.includes('userNotificationTypes'),
            cellRenderer: 'CellText',
            sortOrder: 500,
            minWidth: 150,
            width: 250,
            cellClass: ['text-center'],
            keyCreator: params => params.data && params.data.input_value && params.data.input_value.toString(),
            cellRendererParams: {
              translate: this.translations.translate,
              readonly: true,
              extraButtons: [
                {
                  id: 1,
                  getVisible: data => {
                    let permissions = this.getPermissionsByVenueIdByType(get(data, 'venue.id')),
                      user = get(data, 'user', {}),
                      currentUser = this.user
                    return !!permissions?.notification_settings_manage || user.id === currentUser.id
                  },
                  action: id => {
                    this.selectedUserVenueId = id
                    this.emailSettingsModalOpen = true
                    //this.$emit('selectedUserVenueId', id)
                  },
                  icon: 'wisk-edit'
                }
              ],
              infoComponentType: 'extraButtons'
            },
            valueGetter: params => ({
              id: params.data && params.data.id,
              notifications_config: get(params, 'data.notifications_config', []),
              input_value: get(params, 'data.notifications_config', [])
                .map(r => {
                  let found = this.userNotificationTypesById[r.type] //confusing that one has type the other has id
                  if (found) {
                    return found.title
                  }
                  return ''
                })
                .join(', ')
            })
          },
          {
            headerName: this.translations.txtGenericRole,
            colId: 'role',
            remove: this.hideColumns.includes('role'),
            cellEditor: 'CellPopMultiselect',
            cellRenderer: cellRenderers.CellPopMultiselect,
            minWidth: 120,
            sortOrder: 600,
            width: 150,
            maxWidth: 200,
            keyCreator: params => params.value.input_value || this.translations.txtGenericGroupTitleMissing,
            enableRowGroup: true,
            valueSetter: params => {
              let roles = get(params, 'data.venue.roles', [])
              params.data.role = roles.find(r => r.id === params.newValue)

              return true
            },
            editable: params => {
              let permissions = this.getPermissionsByVenueIdByType(get(params, 'data.venue.id')),
                user = get(params, 'data.user', {}),
                currentUser = this.user

              return !!(permissions && permissions.user_role_manage) && user.id !== currentUser.id
            },
            cellEditorParams: {
              autoOpen: true,
              translations: this.translations,
              save: (value, id) => {
                if (value && id) {
                  this.save(value, id, 'role_id')
                }
              },
              required: true,
              multiselectWidth: 280
            },
            valueGetter: params => {
              let permissions = this.getPermissionsByVenueIdByType(get(params, 'data.venue.id')),
                user = get(params, 'data.user', {}),
                currentUser = this.user,
                roles = get(params, 'data.venue.roles', []).filter(r => r && !r.archived),
                role = roles.find(r => r.id === params?.data?.role?.id)

              return {
                id: params.data && params.data.id,
                venueId: get(params, 'data.venue.id'),
                userId: get(params, 'data.user.id'),
                items: roles,
                input_value: role,
                readonly: !(permissions && permissions.user_role_manage) || user.id === currentUser.id
              }
            }
          },
          {
            headerName: this.translations.txtAuthBusinessRole,
            colId: 'business_role',
            remove: !this.user.god_mode || this.hideColumns.includes('business_role'),
            minWidth: 120,
            sortOrder: 650,
            width: 150,
            maxWidth: 200,
            field: 'user.business_role'
          },
          {
            colId: 'more',
            remove: !this.user.god_mode || this.hideColumns.includes('more'),
            sortOrder: 2800,
            headerName: this.translations.columnDetails,
            minWidth: 100,
            width: 100,
            cellClass: ['cell-more-details'],
            cellRenderer: 'CellMoreDetails',
            suppressColumnsToolPanel: true,
            suppressSortingToolPanel: true,
            suppressFiltersToolPanel: true,
            cellRendererParams: {
              excludeFromExport: true,
              translations: this.translations,
              onClick: (id, data) => {
                this.setGlobalAction({ type: 'userEdit', action: { user: data.user, userId: id, onChange: this.refresh } })
              }
            },
            valueGetter: params => ({
              id: params.data && params.data.user && params.data.user.id,
              group: !!params.node.group,
              user: params.data && params.data.user
            })
          }
        ]
      }
    }
  },
  beforeUnmount() {
    this.gridApi = null
  },
  watch: {
    user: {
      handler: 'createColumnDefs',
      immediate: true
    },
    emailSettingsModalOpen(emailSettingsModalOpen) {
      if (!emailSettingsModalOpen) {
        this.selectedUserVenueId = null
      }
    }
  }
}
</script>
