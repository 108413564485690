<template>
  <div class="image-view flex-grow-1" v-if="mounted" @click="$emit('click', $event)">

    <v-menu :triggers="['hover', 'focus']" v-if="mounted" :delay="delay" :disabled="popoverDisabled" class="mx-auto h-100">
      <img :src="srcDisplay" :class="imgClass" :loading="loading" />

      <icon :class="`text-${hoverIconVariant}`" class="image-view-overlay-icon" :name="hoverIcon" v-if="hoverIcon" />

      <template v-slot:popper>
        <div class="p-2">
          <img :src="srcHover" />
        </div>
      </template>
    </v-menu>
    <slot></slot>
  </div>
  <div v-else> Image loading... </div>
</template>

<script>
import { cloudImage } from '@/modules/utils'

export default {
  name: 'ImageView',
  emits: ['click'],
  props: {
    hoverIcon: String,
    popoverDisabled: Boolean,
    hoverIconVariant: {
      type: String,
      default: 'primary'
    },
    src: {
      type: String,
      default: '3d5df52e-18ea-4d36-9d26-7b3059f73a5f.png'
    },
    filterDisplay: {
      type: String,
      default: 'thumb'
    },
    filterHover: {
      type: String,
      default: 'mdLarge'
    },
    imgClass: { type: String, default: 'h-100' },
    loading: String
  },
  data() {
    return {
      delay: { show: 1000, hide: 0 },
      mounted: false
    }
  },
  computed: {
    srcHover() {
      return cloudImage(this.src, this.filterHover)
    },
    srcDisplay() {
      return cloudImage(this.src, this.filterDisplay)
    }
  },
  mounted() {
    this.mounted = true
  }
}
</script>

<style lang="scss">
.popover {
  max-width: none;
}

.image-view {
  cursor: pointer;
  //max-width: 50px;
  position: relative;

  .image-view-overlay-icon {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: none;
    pointer-events: none;
    box-shadow: 0px 0px 6px 6px rgba(255, 255, 255, 0.7);
    background-color: rgba(255, 255, 255, 0.7);
  }

  &:hover {
    .image-view-overlay-icon {
      display: block;
    }
  }
}
</style>
